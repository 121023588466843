import {SET_SCORE} from "sagas/player/actions/actionTypes"

/**
 * Action called (only) by saga to set game info
 */
export function setScore(answer, points) {
    return {
        type: SET_SCORE,
        answer,
        points
    }
}
