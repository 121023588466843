import React from "react"
import {connect} from "react-redux"
import ChooseTeam from "views/player/chooseTeam/components/chooseTeam"
import {joinTeam} from "sagas/player/actions/game"

let Container = ({...props}) => <ChooseTeam {...props}/>

const mapStateToProps = (state) => ({
    game: state.global.game
})
const mapDispatchToProps = (dispatch) => ({
    joinTeam: (team) => dispatch(joinTeam(team)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
