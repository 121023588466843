import {combineReducers} from "redux"
import {question} from "sagas/player/reducers/views/playing/question"
import {score} from "sagas/player/reducers/views/playing/score"
import {result} from "sagas/player/reducers/views/playing/result"

export default combineReducers({
    question,
    score,
    result
})
