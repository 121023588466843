import React, { Component, Fragment } from "react"
import { TweenMax, Elastic } from "gsap"
import { debounce } from "lodash"
import {
    GAME_ALREADY_STARTED,
    GENERIC_ERROR,
    INVALID_NICKNAME,
    NICKNAME_ALREADY_USED,
} from "sagas/player/actions/game"
import {
    translate,
    remToPx,
    scrollToTop,
    isNameForbidden,
    isAlphabeticString,
    stripExtraSpaces,
    stripAccents,
    getImageUrl,
} from "utils"
import Logo from "views/owner/common/logo"

class EnterNickname extends Component {
    constructor() {
        super()
        this.joinGame = debounce(this.joinGame, 500)
    }

    state = {
        text: "",
        error: "",
    }

    componentDidMount() {
        scrollToTop()
        // this.setupAnimations()
    }

    setupAnimations() {
        TweenMax.to(this.firstRef, 1, {
            y: remToPx(-120),
            ease: Elastic.easeOut.config(0.6, 0.9),
        })
        TweenMax.to(this.secondRef, 1, {
            y: remToPx(-120),
            ease: Elastic.easeOut.config(0.6, 0.9),
        })
    }

    joinGame(nickname) {
        this.props.joinGame(nickname)
    }

    formatNicknameForSubmit(nickname) {
        let n = stripAccents(nickname) // strip accents [à, ä, è ...]
        // remove spaces from the beggining and end of string, remove duplicated spaces in the middle of the string
        n = stripExtraSpaces(n)
        return n
    }

    handleChange(e) {
        // TODO only letters, digits and space
        this.setState({ text: e.target.value })
    }

    handleSubmit() {
        const { setGameState } = this.props
        scrollToTop()
        let nickname = this.state.text
        // show fade-in error or submit (on submit) only if there are no already input warnings (in red)
        if (!this.inputWarning) {
            if (nickname.length < 3 || isNameForbidden(nickname)) {
                setGameState(INVALID_NICKNAME) // fadein error
            } else {
                // Before submit remove accents and unnecessary spaces
                nickname = this.formatNicknameForSubmit(nickname)
                this.joinGame(nickname)
            }
        }
    }

    get inputWarning() {
        if (
            this.state.text.length > 0 &&
            !isAlphabeticString(this.state.text)
        ) {
            return translate("p-only-letters-allowed")
        } else if (this.state.text.length > 30) {
            return translate("p-nickname-too-long")
        } else {
            return null
        }
    }

    get error() {
        const { game } = this.props
        const invalidNickname =
            game.state === INVALID_NICKNAME ? (
                <div className="glickon_alert_error">
                    {translate("p-invalid-nickname")}
                </div>
            ) : null
        const nicknameAlreadyUsed =
            game.state === NICKNAME_ALREADY_USED ? (
                <div className="glickon_alert_error">
                    {translate("p-nickname-already-used")}
                </div>
            ) : null
        const gameAlreadyStarted =
            game.state === GAME_ALREADY_STARTED ? (
                <div className="glickon_alert_error">
                    {translate("p-game-started-error")}
                </div>
            ) : null
        const gameGenericError =
            game.state === GENERIC_ERROR ? (
                <div className="glickon_alert_error">
                    {translate("p-generic-error")}
                </div>
            ) : null

        return (
            <Fragment>
                {invalidNickname}
                {nicknameAlreadyUsed}
                {gameAlreadyStarted}
                {gameGenericError}
            </Fragment>
        )
    }

    render() {
        let { game } = this.props
        game = {
            data: {
                info: {
                    ...game.data
                }
            }
        }
        return (
            <article className="w-full h-screen flex relative flex-col">
                <section className="flex flex-col pt-20 m-auto">
                    <div>
                        <Logo showDefault={false} game={game} hidePin={true}></Logo>
                    </div>
                    <div className="mt-6">
                        <p className="gt text-4xl text-gray-900 m-auto text-center">Manca poco!</p>
                        <p className="text-sm text-gray-400 m-auto text-center w-[60%]">{translate("p-enter-nickname")}</p>
                    </div>
                    {this.inputWarning ?
                        <div className="m-auto w-full h-auto p-2">
                            <div className="glickon_alert_warning">{this.inputWarning}</div>
                        </div> : ''
                    }
                    {this.error && <div className="m-auto w-full h-auto p-2">{this.error}</div>}
                    <div className="mt-6 w-full">
                        <input className="glickon_input" placeholder="Inserisci il nickname" onChange={(e) => this.handleChange(e)} type="text" />
                        <button className="glickon_button" disabled={this.inputWarning} onClick={() => { this.handleSubmit() }}>Gioca</button>
                    </div>
                </section>
                <section className="w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-full m-auto flex items-center justify-center">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                    </div>
                </section>
            </article>
        )
    }
}

export default EnterNickname
