import {SET_GAME} from "sagas/owner/actions/actionTypes"

export function game(state = {state: null, data: null}, action) {
    switch (action.type) {
        case SET_GAME:
            return {
                state: null,
                data: action.data,
            }

        default:
            return state
    }
}
