import * as request from "axios"
import humps from "humps"
import { call, put, takeLatest } from "redux-saga/effects"
import { GET_GAME } from "sagas/player/actions/actionTypes"
import { ENTER_NICKNAME, REGISTRATION } from "appStates/player"
import { setAppState } from "sagas/player/actions/appState"
import { setGame } from "sagas/player/actions/game/setGame"
import { setGameState } from "sagas/player/actions/game/setGameState"
import { reportError } from "sagas/global/actions/error"

// game states
export const LOADING = 0,
    FETCHING = 1,
    FETCHED = 2,
    NOT_FOUND = 3,
    INVALID_PIN = 4,
    INVALID_NICKNAME = 5,
    NICKNAME_ALREADY_USED = 6,
    GAME_ALREADY_STARTED = 7,
    AGE_OR_TERMS_NOT_CONFIRMED = 8,
    INVALID_MAIL = 9,
    INVALID_PHONE = 10,
    GENERIC_ERROR = 666,
    // GiGroup custom fields
    INVALID_SPECIALIZATION = 90,
    INVALID_BIRTHDAY = 91,
    INVALID_OPTIONAL_FLAG_CONSENT = 92,
    INVALID_MY_GI_GROUP_CONSENT = 93

export function getGame(pin) {
    return {
        type: GET_GAME,
        pin,
    }
}

function* getGameSaga(action) {
    try {
        const apiUrl = `/api/player/games/${action.pin}`
        const response = yield call(request.get, apiUrl)
        const game = humps.camelizeKeys(response.data)
        yield put(setGame(game))
        if (game.anonymous) {
            yield put(setAppState(ENTER_NICKNAME))
        } else {
            yield put(setAppState(REGISTRATION))
        }
    } catch (error) {
        if (error.response.status === 404) {
            yield put(setGameState(NOT_FOUND))
        } else if (error.response.status === 400) {
            yield put(setGameState(GAME_ALREADY_STARTED))
        }
        reportError(error)
    }
}

export function* getGameWatcher() {
    yield takeLatest(GET_GAME, getGameSaga)
}
