import React from "react"
import {connect} from "react-redux"
import Playing from "views/player/playing/components/playing"
import {sendAnswer, resetScore, resetQuestionResult} from "sagas/player/actions/playing"

let Container = ({...props}) => <Playing {...props}/>

const mapStateToProps = (state) => ({
    game: state.global.game,
    currentQuestion: state.views.playing.question.currentQuestion,
    currentQuestionNumber: state.views.playing.question.currentQuestionNumber,
    timeElapsed: state.views.playing.question.timeElapsed,
    score: state.views.playing.score,
    result: state.views.playing.result
})

const mapDispatchToProps = (dispatch) => ({
    sendAnswer: (pin, nickname, playerKey, answer) => dispatch(sendAnswer(pin, nickname, playerKey, answer)),
    resetScore: () => dispatch(resetScore()),
    resetQuestionResult: () => dispatch(resetQuestionResult())
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
