import * as request from "axios"
import humps from "humps"
import { call, put, select, takeLatest, delay } from "redux-saga/effects"
import { setCookie } from "utils"
import { PLAYING, CHOOSE_TEAM } from "appStates/player"
import { setAppState } from "sagas/player/actions/appState"
import constants from "appConstants"
import { JOIN_GAME } from "sagas/player/actions/actionTypes"
import { setGameState } from "sagas/player/actions/game/setGameState"
import { setPlayer } from "sagas/player/actions/game/setPlayer"
import { NICKNAME_ALREADY_USED, GENERIC_ERROR, GAME_ALREADY_STARTED } from "sagas/player/actions/game/getGame"
import { setupPusherForPlayer } from "channels"
import { reportError } from "sagas/global/actions/error"
import moment from "moment"

const GLICKON_ID = 1
const GIGROUP_ID = 159

const ERROR_GAME_ALREADY_STARTED = "Game cannot be accessed"
const ERROR_NICKNAME_TAKEN = "Player already exists"

export function joinGame(
    nickname,
    first_name,
    last_name,
    email,
    telephone,
    optionalFlagConsent,
    birthday,
    specialization,
    myGiGroupConsent
) {
    return {
        type: JOIN_GAME,
        nickname,
        first_name,
        last_name,
        email,
        telephone,
        optionalFlagConsent,
        birthday,
        specialization,
        myGiGroupConsent,
    }
}

function* joinGameSaga(action) {
    try {
        // debounce by 500ms
        yield delay(500)
        const game = yield select((state) => state.global.game)
        const apiUrl = `/api/player/games/${game.data.pin}/join`

        // optional flag when registration is required (game.data.anonymous === false)
        const optionalFlag =
            game.data.optionalFlagText ||
                game.data.company === GIGROUP_ID ||
                game.data.company === GLICKON_ID
                ? { optional_flag: action.optionalFlagConsent }
                : null // eslint-disable-line
        const customFields =
            action.specialization || action.birthday || action.myGiGroupConsent
                ? {
                    specialization: action.specialization,
                    birthday: moment(action.birthday).format("YYYY-MM-DD"),
                    my_gigroup_consent: action.myGiGroupConsent || false, // make it boolean if null
                }
                : null
        console.log("joinGameData action", action)
        let loginData = {
            nickname: action.nickname,
            email: action.email,
            telephone: action.telephone,
            first_name: action.first_name,
            last_name: action.last_name,
            ...optionalFlag,
        }
        if (customFields) {
            loginData.custom_fields = customFields
        }

        const payload = !game.data.anonymous
            ? loginData
            : { nickname: action.nickname }

        const response = yield call(request.post, apiUrl, payload)
        const { playerKey } = humps.camelizeKeys(response.data)

        yield put(
            setPlayer({
                nickname: action.nickname.toLowerCase(),
                playerKey: playerKey,
            })
        )
        if (game.data.gameMode === constants.MODE_GAME_FOR_TEAMS) {
            yield put(setAppState(CHOOSE_TEAM))
        } else {
            yield put(setAppState(PLAYING))
            const cookieData = {
                pin: game.data.pin,
                nickname: action.nickname.toLowerCase(),
                playerKey: playerKey,
            }
            setCookie(
                "play.glickon.com.game",
                JSON.stringify(cookieData),
                30 * 60
            )
        }
        setupPusherForPlayer(game.data.pin, action.nickname, playerKey)
    } catch (error) {
        console.log("error", error)
        console.log("error", error.response.data.title)
        if (error.response.data.title === ERROR_GAME_ALREADY_STARTED) {
            yield put(setGameState(GAME_ALREADY_STARTED))
        } else if (error.response.data.title === ERROR_NICKNAME_TAKEN) {
            yield put(setGameState(NICKNAME_ALREADY_USED))
        } else {
            yield put(setGameState(GENERIC_ERROR))
        }
        reportError(error)
    }
}

export function* joinGameWatcher() {
    yield takeLatest(JOIN_GAME, joinGameSaga)
}
