import {clone} from "lodash"
import {SET_PLAYER_LEADERBOARD, SET_PODIUM} from "sagas/player/actions/actionTypes"

export function playerLeaderboard(state = {playerLeaderboard: null, podium: null}, action) {
    let newState
    switch (action.type) {
        case SET_PLAYER_LEADERBOARD:
            newState = clone(state)
            newState.playerLeaderboard = action.data.leaderboard
            return newState
        case SET_PODIUM:
            newState = clone(state)
            newState.podium = action.data
            return newState
        default:
            return state
    }
}
