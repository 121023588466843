import {SET_PLAYERS} from "sagas/owner/actions/actionTypes"

export function players(state = {numberOfPlayers: 0, latestJoinedPlayers: []}, action) {
    switch (action.type) {
        case SET_PLAYERS:
            return action.data

        default:
            return state
    }
}
