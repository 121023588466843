import React from "react"
import {connect} from "react-redux"
import EnterNickname from "views/player/enterNickname/components/enterNickname"
import {joinGame, setGameState} from "sagas/player/actions/game"

let Container = ({...props}) => <EnterNickname {...props}/>

const mapStateToProps = (state) => ({
    game: state.global.game
})
const mapDispatchToProps = (dispatch) => ({
    joinGame: (nickname) => dispatch(joinGame(nickname)),
    setGameState: (state) => dispatch(setGameState(state))
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
