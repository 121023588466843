import classNames from "classnames"
import React, { Component } from "react"
import { getImageUrl, scrollToTop, translate } from "utils"
import Logo from "views/owner/common/logo"

class ChooseTeam extends Component {
    state = {
        selected: null
    }

    componentDidMount() {
        scrollToTop()
    }

    handleTouch(team) {
        const { joinTeam } = this.props
        this.setState({ selected: team })
        setTimeout(() => joinTeam(team), 1000)
    }

    render() {
        const { game: { data: { teams } } } = this.props
        let localGame = {
            data: {
                info: {
                    ...this.props.game.data
                }
            }
        }
        return (
            <article className="w-full h-screen place-content-center place-items-center flex relative">
                <section className="flex flex-col">
                    <div>
                        <Logo showDefault={false} game={localGame} hidePin={true}></Logo>
                    </div>
                    <div className="mt-6">
                        <p className="gt text-4xl text-gray-900 m-auto text-center">Ci sei quasi!</p>
                        <p className="text-sm text-gray-400 m-auto text-center w-[60%]">{translate("p-choose-group")}</p>
                    </div>
                    <div className="mt-6 w-full overflow-scroll max-h-[40vh]">
                        {teams.map(team => {
                            return (
                                <div key={`team-${team}`} className={classNames({ 'selected': team === this.state.selected }, 'glickon_team_wrapper')}>
                                    <div
                                        onClick={() => this.handleTouch(team)}>
                                        {team}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
                <section className="fixed bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-full m-auto flex items-center justify-center">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                    </div>
                </section>
            </article>
        )
    }
}

export default ChooseTeam
