import humps from "humps"
import {SET_ANSWERS} from "sagas/owner/actions/actionTypes"

/**
 * Action called (only) by saga to set game info
 */
export function setAnswers(data) {
    return {
        type: SET_ANSWERS,
        data: humps.camelizeKeys(data)
    }
}
