import React, { Component } from "react"
import { TimelineMax, Power0 } from "gsap"
import { translate, remToPx, getImageUrl } from "utils"
import classNames from "classnames"
import JSConfetti from 'js-confetti'

class PlayerLeaderboard extends Component {

    componentDidMount() {
        const jsConfetti = new JSConfetti()
        let { getPlayerLeaderboard, pin, nickname } = this.props
        getPlayerLeaderboard(pin, nickname)
        setTimeout(() => {
            jsConfetti.addConfetti({
                confettiColors: [
                    '#FFCDC2', '#92C6EA', '#CBE2AB', '#FAD195'
                ],
            })
        }, 600)
        window.scrollTo(0, 0);
    }

    get leaderboard() {
        let { playerLeaderboard, podium, nickname, myTeam } = this.props
        if (podium && playerLeaderboard) {
            return playerLeaderboard.map(
                (player, index) => {
                    let isCurrentPlayer = nickname === player.entity
                    if (myTeam) {
                        isCurrentPlayer = myTeam === player.entity
                    }
                    return <div className={classNames({
                        'bg-primary-100': isCurrentPlayer
                    }, "w-full flex justify-between items-center glickon_leaderboard_border pr-6 pl-6 pt-2 pb-2"
                    )}>
                        <div className={classNames("w-[80%] lowercase", { 'text-primary-500': isCurrentPlayer })}>{player.rank}  {player.entity}</div>
                        <div className="w-[20%] flex justify-end items-center">
                            <div className={classNames({
                                "small": isCurrentPlayer
                            }, 'glickon_badge_pt')}>{player.score}pt</div>
                        </div>
                    </div>
                }
            )
        } else {
            return <div>&nbsp;</div>
        }
    }
    /* eslint-disable no-restricted-globals */
    render() {
        let { podium } = this.props
        return (
            <article className="w-full h-screen relative">
                <section>
                    <div className="p-6 text-center">
                        <p className="gt text-4xl text-gray-900 m-auto text-center">Classifica finale</p>
                    </div>
                    <div className="p-6 flex justify-between items-center">
                        {podium[1] ?
                            <div className="mt-14 w-[33%]">
                                <img src={getImageUrl('second_big.svg')} alt="Second" className="m-auto" />
                                <p className="text-gray-900 text-lg text-center">{podium[1].entity}</p>
                                <p className="text-gray-500 text-sm text-center">{podium[1].score}pt</p>
                            </div> : <div className="w-[33%]">&nbsp;</div>
                        }
                        {podium[0] ?
                            <div className="w-[33%]">
                                <img src={getImageUrl('first_big.svg')} alt="First" className="m-auto" />
                                <p className="text-gray-900 text-lg text-center">{podium[0].entity}</p>
                                <p className="text-gray-500 text-sm text-center">{podium[0].score}pt</p>
                            </div> : <div className="w-[33%]">&nbsp;</div>
                        }
                        {
                            podium[2] ?
                                <div className="mt-14 w-[33%]">
                                    <img src={getImageUrl('third_big.svg')} alt="Third" className="m-auto" />
                                    <p className="text-gray-900 text-lg text-center">{podium[2].entity}</p>
                                    <p className="text-gray-500 text-sm text-center">{podium[2].score}pt</p>
                                </div> : <div className="w-[33%]">&nbsp;</div>
                        }
                    </div>
                    <div className="h-[45vh] overflow-scroll">
                        {this.leaderboard}
                    </div>
                </section>
                <section className="fixed bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-full m-auto flex items-center justify-center">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                    </div>
                </section>
            </article>
        )
    }
}

export default PlayerLeaderboard
