import {clone} from "lodash"
import {SET_SCORE, RESET_SCORE} from "sagas/player/actions/actionTypes"

export function score(state = {answer: null, points: 0}, action) {
    let newState
    switch (action.type) {
        case SET_SCORE:
            newState = clone(state)
            newState.answer = action.answer
            newState.points = action.points
            return newState
        case RESET_SCORE:
            newState = clone(state)
            newState.answer = null
            newState.points = 0
            return newState

        default:
            return state
    }
}
