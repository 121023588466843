import humps from "humps"
import {SET_QUESTION_RESULT} from "sagas/player/actions/actionTypes"

/**
 * Action called by pusher event
 */
export function setQuestionResult(result) {
    return {
        type: SET_QUESTION_RESULT,
        result: humps.camelizeKeys(result)
    }
}
