import {combineReducers} from "redux"
import {appStateOwner} from "sagas/owner/reducers/global/appStateOwner"
import {game} from "sagas/owner/reducers/global/game"
import {results} from "sagas/owner/reducers/global/results"

export default combineReducers({
    appStateOwner,
    game,
    results
})
