import React from "react"
import { connect } from "react-redux"
import { getGameLeaderboard } from "sagas/owner/actions/gameLeaderboard"
import GameLeaderboard from "views/owner/gameLeaderboard/components/gameLeaderboard"

let Container = ({ ...props }) => <GameLeaderboard {...props} />

const mapStateToProps = (state) => ({
    game: state.global.game,
    gameLeaderboard: state.views.gameLeaderboard
})
const mapDispatchToProps = (dispatch) => ({
    getGameLeaderboard: (pin, ownerKey, callback) => dispatch(getGameLeaderboard(pin, ownerKey, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
