import React from "react"
import {connect} from "react-redux"
import WaitingForPlayers from "views/owner/waitingForPlayers/components/waitingForPlayers"
import {getGame} from "sagas/owner/actions/game"
import {setAppState} from "sagas/owner/actions/appState"
import {O_PLAYING} from "appStates/owner"

let Container = ({...props}) => <WaitingForPlayers {...props}/>

const mapStateToProps = (state) => ({
    game: state.global.game,
    waitingForPlayers: state.views.waitingForPlayers,
    appState: state.global.appStateOwner
})
const mapDispatchToProps = (dispatch) => ({
    getGame: (pin, ownerKey) => dispatch(getGame(pin, ownerKey)),
    startGame: () => dispatch(setAppState(O_PLAYING))
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
