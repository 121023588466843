import React from "react"
import {connect} from "react-redux"
import GamePin from "views/player/common/gamePin/components/gamePin"

let Container = ({...props}) => <GamePin {...props}/>

const mapStateToProps = (state) => ({gamePin: state.global.game.data.pin})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
