import React, { Component } from "react"
import Question from "views/owner/playing/components/question"
import QuestionResults from "views/owner/common/questionResults"
import { O_GAME_LEADERBOARD, O_SURVEY_END } from "appStates/owner"
import constants from "appConstants"
import gamePin from "views/player/common/gamePin/components/gamePin"

class Playing extends Component {
    componentDidMount() {
        this.getNextQuestion()
        window.addEventListener("beforeunload", this.onBeforeUnload)
    }

    componentDidUpdate(prevProps, prevState) {
        let { question, resetAnswers, resetQuestionResult, game: { data: { info: { gameMode } } } } = this.props
        if (prevProps.question.currentQuestionNumber !== question.currentQuestionNumber && question.currentQuestionNumber >= 1) {
            resetAnswers()
            resetQuestionResult()
            clearInterval(this.gameTimer)

            /* Don't remove! The following condition might look overcomplicated, but there is corner-case,
                when quiz has only 1 question. (prevProps.question.currentQuestionNumber === 0)
            */
        } else if (prevProps.question.currentQuestionNumber !== question.currentQuestionNumber && question.currentQuestionNumber === null) {
            if (gameMode === constants.MODE_SURVEY) {
                this.props.setAppState(O_SURVEY_END)
            } else {
                this.props.setAppState(O_GAME_LEADERBOARD)
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload)
    }

    onBeforeUnload(e) {
        e.preventDefault()
        e.returnValue = "Do you really want to leave our brilliant application?"
    }

    getNextQuestion() {
        let { pin, ownerKey, getNextQuestion } = this.props
        getNextQuestion(pin, ownerKey)
    }

    getQuestionResult() {
        let { pin, ownerKey, getQuestionResult } = this.props
        getQuestionResult(pin, ownerKey)
    }

    get content() {
        let { question, result, liveAnswers, game, waitingForPlayers } = this.props
        if (game && game.data) {
            if (result) {
                return <QuestionResults
                    waitingForPlayers={waitingForPlayers}
                    currentQuestion={question.currentQuestion}
                    currentQuestionNumber={question.currentQuestionNumber}
                    game={game}
                    result={result}
                    isSurvey={game.data.info.gameMode === constants.MODE_SURVEY}
                    liveAnswers={liveAnswers}
                    getNextQuestion={this.getNextQuestion.bind(this)} />
            } else {
                return (
                    <div>
                        <Question
                            currentQuestion={question.currentQuestion}
                            currentQuestionNumber={question.currentQuestionNumber}
                            timeElapsed={question.timeElapsed}
                            getQuestionResult={this.getQuestionResult.bind(this)}
                            isSurvey={game.data.info.gameMode === constants.MODE_SURVEY}
                            liveAnswers={liveAnswers}
                            game={game}
                            waitingForPlayers={waitingForPlayers} />
                        {this.getResultButton}
                    </div>
                )
            }
        } else return null
    }

    render() {
        return this.props.question.currentQuestion ? this.content : null
    }
}

export default Playing
