import {clone} from "lodash"
import {SET_QUESTION, SET_TIME_ELAPSED} from "sagas/player/actions/actionTypes"

let initialState = {
    timeElapsed: 0
}

export function question(state = initialState, action) {
    let nextState
    switch (action.type) {
        case SET_QUESTION:
            nextState = {...initialState, ...action.data}
            return nextState
        case SET_TIME_ELAPSED:
            nextState = clone(state)
            if (nextState) {
                nextState.timeElapsed = action.timeElapsed
            }
            return nextState
        default:
            return state
    }
}
