import React from "react"
import {connect} from "react-redux"
import EnterPin from "views/player/enterPin/components/enterPin"
import {login, getGame, setGameState} from "sagas/player/actions/game"

let Container = ({...props}) => <EnterPin {...props}/>

const mapStateToProps = (state) => ({
    gameState: state.global.game.state
})
const mapDispatchToProps = (dispatch) => ({
    login: (pin, nickname, playerKey, team) => dispatch(login(pin, nickname, playerKey, team)),
    getGame: (data) => dispatch(getGame(data)),
    setGameState: (state) => dispatch(setGameState(state))
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
