import classNames from "classnames"
import React, { Component } from "react"
import { TimelineMax, Power0, Bounce, Back, TweenMax } from "gsap"
import { getImageUrl, translate, remToPx } from "utils"

class Progress extends Component {

    constructor(props) {
        super(props)
        this.state = {
            div: null
        }
    }

    componentDidMount() {
        if (this.state.div && this.props.time) {
            TweenMax.to(this.state.div, this.props.time + 3, { width: '0%' })
        } else {
            TweenMax.to(this.state.div, 0, { width: '0%' })
        }
    }

    componentDidUpdate() {
    }

    render() {
        const { color, small, becomesRed } = this.props
        let bg = color
        if (becomesRed) {
            bg = '#E27A6F'
        }
        return (
            <div className={classNames({ 'small': small }, "relative glickon_progress_wrapper")}>
                <div ref={(div) => this.state.div = div} className="glickon_progress" style={{ backgroundColor: bg }}>&nbsp;</div>
            </div >
        )
    }
}

export default Progress
