import {combineReducers} from "redux"
import {question} from "sagas/owner/reducers/views/playing/question"
import {answers} from "sagas/owner/reducers/views/playing/answers"
import {result} from "sagas/owner/reducers/views/playing/result"

export default combineReducers({
    question,
    answers,
    result
})
