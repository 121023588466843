import humps from "humps"
import {SET_PODIUM} from "sagas/player/actions/actionTypes"

/**
 * Action called (only) by saga to set game top 3 results
 */
export function setPodium(data) {
    return {
        type: SET_PODIUM,
        data: humps.camelizeKeys(data),
    }
}
