export default {
    "p-welcome": {
        en: "Welcome",
        it: "Ciao!",
    },
    "p-enter-pin": {
        en: "Enter the GAMEPIN you see on the monitor",
        it: "Inserisci il GAMEPIN che vedi sul monitor",
    },
    "p-pin-error": {
        it: "Pin errato! Controlla di nuovo!",
        en: "Pin not found! Please check and retry!",
    },
    "p-game-started-error": {
        en: "Game already started!",
        it: "Questo game è già iniziato",
    },
    "p-invalid-pin": {
        en: "We don’t recognize GAMEPIN!",
        it: "Non riconosciamo il GAMEPIN inserito.",
    },
    "p-check-and-retry": {
        en: "Please check and retry!",
        it: "Controlla e riprova!",
    },
    "p-enter-nickname": {
        en: "Choose your nickname",
        it: "Scegli il tuo nickname per partecipare al gioco",
    },
    "p-invalid-nickname": { en: "Invalid nickname", it: "Questo nickname non è valido" },
    "p-nickname-already-used": {
        en: "Nickname already used",
        it: "Nickname già utilizzato!",
    },
    "p-generic-error": {
        en: "Error while joining game",
        it: "Errore durante l'inizio del game",
    },
    "p-only-letters-allowed": {
        en: "Only letters and spaces allowed",
        it: "Solo lettere e spazio ammessi",
    },
    "p-nickname-too-long": {
        en: "Maximum 30 characters",
        it: "Massimo 30 caratteri",
    },
    "p-nickname-too-short": {
        en: "Minimum 3 characters",
        it: "Minimo 3 caratteri",
    },
    "p-send": { en: "Send", it: "Invia" },
    "p-go": { en: "Go!", it: "Go!" },
    "p-game-pin": { en: "Game pin", it: "Game pin" },
    "p-leaderboard": { en: "Leaderboard", it: "Classifica finale" },
    "p-question": { en: "Question", it: "Domanda" },
    "p-result-title-correct": { en: "Very good!", it: "Complimenti, risposta esatta!" },
    "p-result-title-wrong": { en: "Bad!", it: "Peccato, risposta errata!" },
    "p-result-title-empty": { en: "You ran out of time!", it: "Peccato, tempo scaduto!" },
    "p-answer-is-correct": {
        en: "Your answer is correct",
        it: "La tua risposta è corretta.",
    },
    "p-answer-is-wrong": { en: "Your answer is wrong", it: "Hai sbagliato!" },
    "p-live-players": { en: "Live players", it: "Giocatori Live" },
    "p-see-your-name-on-screen": {
        it: "Ci sei quasi!",
        en: "You're almost there!",
    },
    "p-game-will-start-soon": {
        en: "The match will begin soon!",
        it: "La sfida inizierà tra qualche istante!",
    },
    "p-loading-text": {
        en: "Loading",
        it: "Caricamento",
    },
    "p-loading-text-sub": {
        en: "Connecting with Glickon Live",
        it: "Connessione in corso con Glickon Live",
    },
    "p-choose-group": {
        en: "Choose your group you want to belong to",
        it: "Scegli il tuo gruppo a cui vuoi appartenere nel gioco",
    },
    "p-do-not-refresh-page": {
        en: "Please do not refresh the page",
        it: "Per favore non riavviare la pagina",
    },
    "p-next-question-will-start-soon": {
        en: "Next question will start soon",
        it: "Tra qualche istante potrai rispondere alla prossima domanda",
    },
    "p-confirm-age": {
        en: "I confirm that I am 16 years of age or older",
        it: "Confermo di avere più di 16 anni",
    },
    "p-confirm-terms-and-conditions": {
        en: "I agree to",
        it: "Dichiaro di aver letto attentamente, di aver compreso e di accettare il contenuto dei",
    },
    "p-terms-and-conditions": {
        en: "Terms and conditions",
        it: "Termini e Condizioni d’Uso",
    },
    "p-privacy": {
        en: "information about the treatment of my personal data issued by the promoter",
        it: "informativa sul trattamento dei dati personali del Promotore",
    },
    "p-name": { en: "Name ", it: "Nome" },
    "p-surname": { en: "Surname", it: "Cognome" },
    "p-email-address": { en: "Email address", it: "Indirizzo email" },
    "p-phone-number": { en: "Phone number", it: "Numero di telefono" },
    "p-age-or-terms-not-confirmed": {
        en: "Please confirm your age and terms & conditions",
        it: "Please confirm your age and terms & conditions",
    },
    "p-enter-your-data-first-part": {
        en: "",
        it: "Prima di inserire i tuoi dati ti preghiamo di prendere visione dell'",
    },
    "p-enter-your-data-second-part": {
        en: "",
        it: "ai sensi dell'art. 13 del Regolamento (EU) 2016/679",
    },
    "p-empty-or-invalid-mail": {
        en: "Please enter valid email",
        it: "Inserisci un indirizzo email valido",
    },
    "p-empty-or-invalid-phone": {
        en: "Please enter valid phone number",
        it: "Inserisci un numero di telefono valido",
    },
    "p-empty-or-invalid-specialization": {
        en: "Please select a specialization",
        it: "Seleziona un indirizzo di studi - area",
    },
    "p-empty-or-invalid-birthday": {
        en: "Please select a birthday",
        it: "Seleziona l'anno di nascita",
    },
    "p-empty-or-invalid-optional-flag-cosent": {
        en: "Please select a valid option",
        it: 'Seleziona "accosento" oppure "non acconsento" nella voce marketing.',
    },
    "p-empty-or-invalid-my-gi-group-cosent": {
        en: "Please select a valid option",
        it: 'Seleziona "accosento" oppure "non acconsento nella voce myGiGroup"',
    },
    "p-confirmation-fields-error": {
        en: "Confirm age and accept Terms and Conditions",
        it: "Conferma età e accetta Termini e Condizioni d’Uso",
    },
    "p-invalid-first-name-last-name": {
        en: "Please type a valid name and surname",
        it: "Inserisci un nome e cognome validi",
    },
}
