import React from "react"
import { getImageUrl, translate } from "utils"

export default () => (
    <section className="glickon_spinner_wrapper">
        <div>
            <div className="flex justify-center items-center mb-4">
                <div className="glickon_spinner"></div>
            </div>
            <div>
                <p className="text-center gt text-4xl text-gray-900 ">{translate("p-loading-text")}</p>
                <p className="text-sm text-gray-400 m-auto text-center">{translate("p-loading-text-sub")}</p>
            </div>
        </div>
        <section className="bottom absolute bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
            <div className="w-full m-auto flex items-center justify-center">
                <img src={getImageUrl('powered.svg')} alt="Logo" />
            </div>
        </section>
    </section>
)
