import {SET_GAME_STATE} from "sagas/player/actions/actionTypes"

/**
 * state can be (null, FETCHING, FETCHED, NOT_FOUND, ERROR)
 */
export function setGameState(state) {
    return {
        type: SET_GAME_STATE,
        state
    }
}
