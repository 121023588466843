import React, { Component, Fragment } from "react"
import { TimelineMax, Power0, Bounce, Back } from "gsap"
import { debounce } from "lodash"
import Checkbox from "commonComponents/checkbox"
import DatePicker from "react-datepicker"
import Logo from "views/owner/common/logo"
import "react-datepicker/dist/react-datepicker.css"
import {
    INVALID_NICKNAME,
    NICKNAME_ALREADY_USED,
    AGE_OR_TERMS_NOT_CONFIRMED,
    INVALID_MAIL,
    INVALID_PHONE,
    INVALID_BIRTHDAY,
    INVALID_SPECIALIZATION,
    INVALID_OPTIONAL_FLAG_CONSENT,
    INVALID_MY_GI_GROUP_CONSENT,
} from "sagas/player/actions/game"
import {
    translate,
    remToPx,
    scrollToTop,
    isNameForbidden,
    validateEmail,
    isAlphabeticString,
    stripAccents,
    stripExtraSpaces,
    getImageUrl,
} from "utils"
import Specialization from "./specialization"
import GiGroupCheck from "./giGroupCheckbox"

// DISCLAMER: THIS PAGE IS A MESS, BRACE YOURSELF!!!

const GLICKON_ID = 1
const GIGROUP_ID = 159

const BirthdayPicker = ({ handleBirthdayChange, birthday }) => {
    const setBirthday = (birthday) => {
        handleBirthdayChange(birthday)
    }

    return (
        <div className="glickon_input_wrapper">
            <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={birthday}
                onChange={(date) => setBirthday(date)}
                showYearDropdown
                placeholderText="Data di nascita (dd/mm/yyyy)"
                className="glickon_input birthday-input"
                openToDate={new Date(2004, 0, 1)}
            />
        </div>
    )
}

class Registration extends Component {
    constructor() {
        super()
        this.joinGame = debounce(this.joinGame, 500)
    }

    state = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        specialization: "",
        birthday: null,
        optionalFlagConsent: null, // null because in this way no checkbox is selected for GiGroup
        myGiGroupConsent: null, // null because in this way no checkbox is selected for GiGroup
        confirmTerms: false,
        error: "",
    }

    componentDidMount() {
        scrollToTop()
        this.setupAnimations()
    }

    setupAnimations() {
        let tl = new TimelineMax({ repeat: -1, repeatDelay: 1 })
        tl.to(this.firstRef, 0.5, { ease: Power0.easeNone, y: remToPx(-1) })
        tl.to(this.firstRef, 1, { ease: Bounce.easeOut, y: remToPx(0) })

    }

    joinGame(
        nickname,
        firstName,
        lastName,
        email,
        phone,
        optionalFlagConsent,
        birthday,
        specialization,
        myGiGroupConsent
    ) {
        this.props.joinGame(
            nickname,
            firstName,
            lastName,
            email,
            phone,
            optionalFlagConsent,
            birthday,
            specialization,
            myGiGroupConsent
        )
    }

    setError(errorText) {
        this.setState({ error: errorText })
    }

    handleFirstNameChange(e) {
        // TODO only letters, digits and space
        this.setState({ firstName: e.target.value })
    }
    handleLastNameChange(e) {
        // TODO only letters, digits and space
        this.setState({ lastName: e.target.value })
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value })
    }

    handlePhoneChange(e) {
        this.setState({ phone: e.target.value })
    }
    handleAgeCheck(e) { }

    handleSpecializationChange(e) {
        this.setState({ specialization: e.value })
    }

    handleBirthdayChange(newDate) {
        this.setState({ birthday: newDate })
        // if the user is under 18 then set automatically myGiGroupConsent to null (initial state)
        // this is needed in a corner case:
        // - the user set the birthday as adult (wrong selection)
        // - then the user check myGiGroup flag to true
        // - then the user change a second time the birtday as under 18
        const today = new Date()
        if (newDate > today.setFullYear(today.getFullYear() - 18)) {
            this.setState({ myGiGroupConsent: null })
        }
    }

    handleOptionalFlagConsentChange(newValue) {
        this.setState({ optionalFlagConsent: newValue })
    }

    handleMyGiGroupConsentChange(newValue) {
        this.setState({ myGiGroupConsent: newValue })
    }

    formatNicknameForSubmit(nickname) {
        let n = stripAccents(nickname) // strip accents [à, ä, è ...]
        // remove spaces from the beggining and end of string, remove duplicated spaces in the middle of the string
        n = stripExtraSpaces(n)
        return n
    }

    handleSubmit(e) {
        // Please do not judge me, I had no time...
        const {
            setGameState,
            game: {
                data: { company },
            },
        } = this.props
        const {
            firstName,
            lastName,
            email,
            phone,
            confirmTerms,
            optionalFlagConsent,
            birthday,
            specialization,
            myGiGroupConsent,
        } = this.state
        scrollToTop()
        e.preventDefault()
        // please rewrite this shit
        // show fade-in error or submit (on submit) only if there are no input warnings (in red)
        if (!this.inputWarning) {
            if (
                `${firstName} ${lastName}`.length < 4 ||
                `${firstName} ${lastName}`.length > 30 ||
                isNameForbidden(`${firstName} ${lastName}`) ||
                !isAlphabeticString(`${firstName} ${lastName}`)
            ) {
                setGameState(INVALID_NICKNAME)
            } else if (!email || !validateEmail(email)) {
                setGameState(INVALID_MAIL)
            } else if (!phone || phone.length < 9) {
                setGameState(INVALID_PHONE)
            } else if (!confirmTerms) {
                setGameState(AGE_OR_TERMS_NOT_CONFIRMED)
            } else if (
                (company === GIGROUP_ID || company === GLICKON_ID) &&
                !specialization
            ) {
                setGameState(INVALID_SPECIALIZATION)
            } else if (
                (company === GIGROUP_ID || company === GLICKON_ID) &&
                !birthday
            ) {
                setGameState(INVALID_BIRTHDAY)
            } else if (
                (company === GIGROUP_ID || company === GLICKON_ID) &&
                optionalFlagConsent === null
            ) {
                setGameState(INVALID_OPTIONAL_FLAG_CONSENT)
            } else if (
                (company === GIGROUP_ID || company === GLICKON_ID) &&
                this.studentIsAdult &&
                myGiGroupConsent === null
            ) {
                setGameState(INVALID_MY_GI_GROUP_CONSENT)
            } else {
                // these errors are already shown in red on input change
                // Before submit remove accents and unnecessary spaces
                this.joinGame(
                    this.formatNicknameForSubmit(`${firstName} ${lastName}`),
                    firstName,
                    lastName,
                    email,
                    phone,
                    optionalFlagConsent,
                    birthday,
                    specialization,
                    myGiGroupConsent
                )
            }
        }

        if (this.secondRef) {
            let tm = new TimelineMax()
            tm.to(this.thirdRef, 0.5, {
                ease: Power0.easeNone,
                y: remToPx(10),
                delay: 0.5,
            })
            tm.to(this.thirdRef, 1.5, {
                ease: Power0.easeNone,
                y: remToPx(-10),
                delay: 3,
            })
        }
    }

    get inputWarning() {
        if (
            this.state.firstName.length > 0 &&
            !isAlphabeticString(this.state.firstName)
        ) {
            return translate("p-only-letters-allowed")
        } else if (this.state.firstName.length > 30) {
            return translate("p-nickname-too-long")
        } else if (
            this.state.lastName.length > 0 &&
            !isAlphabeticString(this.state.lastName)
        ) {
            return translate("p-only-letters-allowed")
        } else if (this.state.lastName.length > 30) {
            return translate("p-nickname-too-long")
        } else {
            return null
        }
    }

    get privacyLink() {
        const {
            game: {
                data: { privacyUrl, company },
            },
        } = this.props
        if (company === GIGROUP_ID || company === GLICKON_ID) {
            // Temporary fix for GiGroup
            return (
                <span className="text-underline">
                    {" "}
                    <a
                        className="link"
                        href="https://storage.googleapis.com/glickon-production-public-uploads/temporary/gigroup/Informativa%20privacy%20accesso%20a%20Glickon%20-%20Sez.%20riservata%20a%20GI_202209.docx"
                        download
                    >
                        {translate("p-privacy")}
                    </a>
                </span>
            )
        } else {
            return privacyUrl ? (
                <span className="text-underline">
                    {" "}
                    <a className="link" href={privacyUrl} download>
                        {translate("p-privacy")}
                    </a>
                </span>
            ) : (
                <span className="text-underline">
                    {" "}
                    <a
                        className="link"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.glickon.com/it/documenti/live/privacy"
                    >
                        {translate("p-privacy")}
                    </a>
                </span>
            )
        }
    }

    get studentIsAdult() {
        // only for GiGroup
        let today = new Date()
        return (
            this.state.birthday &&
            this.state.birthday <= today.setFullYear(today.getFullYear() - 18)
        )
    }

    get registrationForm() {
        console.log("props")
        console.log(this.props)
        console.log("state")
        console.log(this.state)
        const {
            game: {
                data: { company, optionalFlagText },
                state,
            },
        } = this.props
        const GIGROUP = company === GIGROUP_ID || company === GLICKON_ID
        const { confirmTerms } = this.state
        // if error is shown and then checkboxes are selected, don't show error
        // const confirmationError = state === AGE_OR_TERMS_NOT_CONFIRMED && !(confirmAge && confirmTerms)
        const confirmationError =
            state === AGE_OR_TERMS_NOT_CONFIRMED && !confirmTerms
        const confirmTermsText = (
            <span>
                {translate("p-confirm-terms-and-conditions")}
                <span className="text-underline">
                    {" "}
                    <a
                        className="link"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.glickon.com/it/documenti/live/termini-e-condizioni"
                    >
                        {translate("p-terms-and-conditions")}
                    </a>{" "}
                </span>
            </span>
        )
        let localGame = {
            data: {
                info: {
                    ...this.props.game.data
                }
            }
        }
        return (
            <Fragment>
                <div ref={div => this.firstRef = div} key="company-logo">
                    <Logo game={localGame} start={false} hidePin={true} showDefault={false}></Logo>
                </div>
                <div
                    key="insert-data"
                >
                    <div className="text-sm text-gray-400 font-light text-left w-[80%] mt-6 mb-6">
                        {translate("p-enter-your-data-first-part")}{" "}
                        {this.privacyLink}{" "}
                        {translate("p-enter-your-data-second-part")}
                    </div>
                </div>
                <div
                    ref={(div) => (this.secondRef = div)}
                    key="registration-form"
                >
                    <form
                        onSubmit={this.handleSubmit.bind(this)}
                        autoComplete="off"
                    >
                        <div id="nickname-input-warning" key="inputWarning">
                            {this.inputWarning}
                        </div>
                        <div className="glickon_input_wrapper">
                            <input
                                className={`glickon_input ${this.inputWarning ? "error" : null
                                    }`}
                                onChange={this.handleFirstNameChange.bind(this)}
                                placeholder={translate("p-name")}
                                value={this.state.firstName}
                            />
                        </div>
                        <div className="glickon_input_wrapper">
                            <input
                                className={`glickon_input ${this.inputWarning ? "error" : null
                                    }`}
                                onChange={this.handleLastNameChange.bind(this)}
                                placeholder={translate("p-surname")}
                                value={this.state.lastName}
                            />
                        </div>
                        <div className="glickon_input_wrapper">
                            <input
                                className="glickon_input"
                                onChange={this.handleEmailChange.bind(this)}
                                placeholder={translate("p-email-address")}
                                value={this.state.email}
                            />
                        </div>
                        {GIGROUP ? (
                            <BirthdayPicker
                                birthday={this.state.birthday}
                                handleBirthdayChange={this.handleBirthdayChange.bind(
                                    this
                                )}
                            />
                        ) : null}
                        <div className="glickon_input_wrapper">
                            <input
                                className="glickon_input"
                                onChange={this.handlePhoneChange.bind(this)}
                                placeholder={translate("p-phone-number")}
                                type="number"
                                value={this.state.phone}
                            />
                        </div>
                        {GIGROUP ? (
                            <Specialization
                                handleSpecializationChange={this.handleSpecializationChange.bind(
                                    this
                                )}
                            />
                        ) : null}
                        <div id="confirmation-wrapper">
                            {confirmationError ? (
                                <div className="text-xs text-error-500 ">
                                    {translate("p-confirmation-fields-error")}
                                </div>
                            ) : null}
                            <div className="checkbox-wrapper">
                                <Checkbox
                                    text={confirmTermsText}
                                    showError={confirmationError}
                                    value={this.state.confirmTerms}
                                    onCheck={() =>
                                        this.setState({
                                            confirmTerms:
                                                !this.state.confirmTerms,
                                        })
                                    }
                                />
                            </div>
                            {GIGROUP ? (
                                <GiGroupCheck
                                    text="Il sottoscritto, presa visione dell’informativa privacy, acconsente al trattamento dei propri dati personali per le finalità di cui all’art. 3.2 dell’informativa, ovvero per consentire a Gi Group S.p.A. di inviare comunicazioni informative e promozionali relative ai servizi offerti da Gi Group S.p.A. (con particolare riferimento alle attività di selezione del personale, ad iniziative di formazione e orientamento al lavoro)."
                                    setChecked={this.handleOptionalFlagConsentChange.bind(
                                        this
                                    )}
                                    checked={this.state.optionalFlagConsent}
                                />
                            ) : null}
                            {/* IMPORTANT NOTE:
                             only for GiGroup the optional flag has always the same text: it's not customisable by contract! */}
                            {optionalFlagText && !GIGROUP ? (
                                <div className="checkbox-wrapper">
                                    <Checkbox
                                        text={optionalFlagText}
                                        showError={confirmationError}
                                        value={this.state.optionalFlagConsent}
                                        onCheck={() =>
                                            this.setState({
                                                optionalFlagConsent:
                                                    !this.state
                                                        .optionalFlagConsent,
                                            })
                                        }
                                    />
                                </div>
                            ) : null}
                            {GIGROUP && this.studentIsAdult ? (
                                <GiGroupCheck
                                    text="Vuoi che i tuoi dati conferiti per l’accesso alla piattaforma Glickon nella sezione riservata a GI siano trattati anche per la registrazione al portale MyGigroup dedicato alla ricerca e selezione di personale (così da ricevere segnalazioni di opportunità di lavoro)?In caso affermativo, ti invieremo un’e-mail con la relativa informativa privacy."
                                    setChecked={this.handleMyGiGroupConsentChange.bind(
                                        this
                                    )}
                                    checked={this.state.myGiGroupConsent}
                                />
                            ) : null}
                        </div>
                        <div className="mt-6 w-full">
                            <button className="glickon_button">Invia</button>
                        </div>
                        {this.state.error}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </form>
                </div>
            </Fragment>
        )
    }

    get error() {
        const { game } = this.props
        const invalidNickname =
            game.state === INVALID_NICKNAME ? (
                <div className="glickon_alert_error">
                    {translate("p-invalid-first-name-last-name")}
                </div>
            ) : null
        const nicknameAlreadyUsed =
            game.state === NICKNAME_ALREADY_USED ? (
                <div className="glickon_alert_error">
                    {translate("p-nickname-already-used")}
                </div>
            ) : null
        const invalidMail =
            game.state === INVALID_MAIL ? (
                <div className="glickon_alert_error">
                    {translate("p-empty-or-invalid-mail")}
                </div>
            ) : null
        const invalidPhone =
            game.state === INVALID_PHONE ? (
                <div className="glickon_alert_error">
                    {translate("p-empty-or-invalid-phone")}
                </div>
            ) : null
        const invalidSpecialization =
            game.state === INVALID_SPECIALIZATION ? (
                <div className="glickon_alert_error">
                    {translate("p-empty-or-invalid-specialization")}
                </div>
            ) : null
        const invalidBirthday =
            game.state === INVALID_BIRTHDAY ? (
                <div className="glickon_alert_error">
                    {translate("p-empty-or-invalid-birthday")}
                </div>
            ) : null
        const invalidOptionalFlagConsent =
            game.state === INVALID_OPTIONAL_FLAG_CONSENT ? (
                <div className="glickon_alert_error">
                    {translate("p-empty-or-invalid-optional-flag-cosent")}
                </div>
            ) : null
        const invalidMyGiGroupConsent =
            game.state === INVALID_MY_GI_GROUP_CONSENT ? (
                <div className="glickon_alert_error">
                    {translate("p-empty-or-invalid-my-gi-group-cosent")}
                </div>
            ) : null

        return (
            <Fragment>
                {invalidNickname}
                {nicknameAlreadyUsed}
                {invalidMail}
                {invalidPhone}
                {invalidSpecialization}
                {invalidBirthday}
                {invalidOptionalFlagConsent}
                {invalidMyGiGroupConsent}
            </Fragment>
        )
    }

    render() {
        const { game } = this.props
        return (
            <article className="relative pt-4">
                <section className="p-6">
                    <div
                        ref={(div) => (this.thirdRef = div)}
                    >
                        {this.error}
                    </div>
                    {this.registrationForm}
                </section>
                <section className="fixed bottom-0 left-0 w-full p-4 mt-14 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-full m-auto flex items-center justify-center">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                    </div>
                </section>
            </article>
        )
    }
}

export default Registration
