import React, { Component } from "react"
import Question from "views/player/playing/components/question"
import QuestionResult from "views/player/playing/components/questionResult"
import WaitingToStart from "views/player/waitingToStart"
import GamePin from "views/player/common/gamePin"
import { getImageUrl, translate } from "utils"
import constants from "appConstants"

class Playing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            answered: false
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.onBeforeUnload)
        // TODO beforeunload is not detected by ios, try to find a way to enforce reload confirmation on ios also
    }
    componentDidUpdate(prevProps, prevState) {
        let { currentQuestionNumber, resetScore, resetQuestionResult } = this.props
        if (prevProps.currentQuestionNumber !== currentQuestionNumber && currentQuestionNumber >= 1) {
            // resetScore()
            resetQuestionResult()
            this.setState({ answered: false })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload)
    }

    onBeforeUnload(e) {
        e.preventDefault()
        e.returnValue = "Do you really want to leave our brilliant application?"
    }

    get content() {
        let { currentQuestion, game, sendAnswer, score, currentQuestionNumber,
            result, resetScore, resetQuestionResult, timeElapsed } = this.props
        let { data, player } = game
        game = {
            data: {
                info: {
                    ...game.data
                }
            }
        }
        if (!currentQuestion) {
            const gameStatus = game.data.status // if game was already started or not (usefull on app reload during the game)
            return (
                <WaitingToStart
                    nickname={player.nickname}
                    gameStatus={gameStatus}
                    gamePin={data.pin}
                    game={game} />
            )
        } else if (result) {
            return (
                <QuestionResult
                    result={result}
                    playerScore={score}
                    player={player}
                    question={currentQuestion}
                    currentQuestionNumber={currentQuestionNumber}
                    game={game}
                    timeElapsed={timeElapsed}
                    resetScore={resetScore}
                    resetQuestionResult={resetQuestionResult}
                    score={score}
                    answered={this.state.answered} />
            )
        } else {
            return (
                <Question
                    player={player}
                    question={currentQuestion}
                    currentQuestionNumber={currentQuestionNumber}
                    sendAnswer={(pin, nickname, key, answer) => {
                        this.props.sendAnswer(pin, nickname, key, answer)
                        this.setState({ answered: true })
                    }}
                    game={game}
                    timeElapsed={timeElapsed}
                    resetScore={resetScore}
                    resetQuestionResult={resetQuestionResult}
                    score={score} />
            )
        }
    }

    get questionNumber() {
        return this.props.currentQuestion ? (
            <div id="question-number">{translate("p-question")}: {this.props.currentQuestionNumber + 1}</div>
        ) : null
    }

    /*
    render() {
        return (
            <div id="player-play-wrapper">
                <div id="top-wrapper">
                    <GamePin />
                    {this.questionNumber}
                </div>
                {this.content}
            </div>
        )
    }
    */

    render() {
        let { game, currentQuestion } = this.props
        game = {
            data: {
                info: {
                    ...game.data
                }
            }
        }
        return (
            <article style={{
                backgroundImage: currentQuestion && `url("${getImageUrl('mobile_bg.svg')}")`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }} className="w-full h-screen flex flex-col relative">
                <section className="w-full">
                    {this.content}
                </section>
                <section className="w-full flex justify-center mt-auto items-center p-2">
                    <GamePin />
                </section>
            </article>
        )
    }
}

export default Playing
