import * as request from "axios"
import humps from "humps"
import {call, put, select, takeLatest} from "redux-saga/effects"
import {setCookie} from "utils"
import {PLAYING} from "appStates/player"
import {setAppState} from "sagas/player/actions/appState"
import {JOIN_TEAM} from "sagas/player/actions/actionTypes"
import {setPlayer} from "sagas/player/actions/game/setPlayer"
import {reportError} from "sagas/global/actions/error"

export function joinTeam(team) {
    return {
        type: JOIN_TEAM,
        team
    }
}

function* joinTeamSaga(action) {
    try {
        const game = yield select((state) => state.global.game)
        const apiUrl = `/api/player/games/${game.data.pin}/join_team`
        const response = yield call(request.post, apiUrl, {player: {nickname: game.player.nickname, player_key: game.player.playerKey}, team: action.team})
        const {team} = humps.camelizeKeys(response.data)
        yield put(setPlayer({nickname: game.player.nickname.toLowerCase(), playerKey: game.player.playerKey, team: action.team}))
        yield put(setAppState(PLAYING))
        const cookieData = {
            pin: game.data.pin,
            nickname: game.player.nickname,
            playerKey: game.player.playerKey,
            team: team
        }
        setCookie("play.glickon.com.game", JSON.stringify(cookieData), 30 * 60)
    } catch (error) {
        console.log("error", error)
        // yield put(setGameState(NICKNAME_ALREADY_USED))
        reportError(error)
    }
}

export function* joinTeamWatcher() {
    yield takeLatest(JOIN_TEAM, joinTeamSaga)
}
