import React, { Component, Fragment } from "react"
import { TimelineMax, Power0, Bounce, Back } from "gsap"
import { GAME_STATUS_PLAYING } from "sagas/player/actions/game"
import { getImageUrl, translate, remToPx } from "utils"
import Logo from "views/owner/common/logo"

class WaitingToStart extends Component {
    componentDidMount() {
        this.setupAnimations()
    }

    setupAnimations() {
        let tl = new TimelineMax({ repeat: -1, repeatDelay: 1 })
        tl.to(this.firstRef, 0.5, { ease: Power0.easeNone, y: remToPx(-1) })
        tl.to(this.firstRef, 1, { ease: Bounce.easeOut, y: remToPx(0) })
        let tm = new TimelineMax({ repeat: -1, repeatDelay: 3 })
        tm.to(this.secondRef, 1, { ease: Back.easeOut.config(1), scale: 1.05 })
        tm.to(this.secondRef, 1, { ease: Back.easeOut.config(1), scale: 1 })

    }

    get title() {
        return this.props.gameStatus === GAME_STATUS_PLAYING ? (
            <div>
                {translate("p-do-not-refresh-page")}<br />
                {translate("p-next-question-will-start-soon")}
            </div>
        ) : (
            <div>
                {translate("p-see-your-name-on-screen")}<br />
                {translate("p-game-will-start-soon")}
            </div>
        )
    }

    render() {
        const { nickname, game } = this.props
        return (
            <section className="h-full pt-20">
                <div ref={div => this.firstRef = div}>
                    <Logo showDefault={false} game={game} hidePin={true}></Logo>
                </div>
                <div ref={div => this.secondRef = div} className="w-[80%] m-auto mt-10">
                    <p className="gt text-4xl text-gray-900 m-auto text-center">{nickname}</p>
                    <p className="text-sm text-gray-400 m-auto text-center w-full mt-2">{this.title}</p>
                </div>
            </section>
        )
    }
}

export default WaitingToStart
