import * as request from "axios"
import humps from "humps"
import {call, put, takeLatest, delay} from "redux-saga/effects"
import {ENTER_PIN, PLAYING} from "appStates/player"
import {deleteCookie} from "utils"
import {setAppState} from "sagas/player/actions/appState"
import {LOGIN} from "sagas/player/actions/actionTypes"
import {setGame} from "sagas/player/actions/game/setGame"
import {setPlayer} from "sagas/player/actions/game/setPlayer"
import {setupPusherForPlayer} from "channels"

export const GAME_STATUS_WAITING = 0
export const GAME_STATUS_PLAYING = 1

export function login(pin, nickname, playerKey, team) {
    return {
        type: LOGIN,
        pin,
        nickname,
        playerKey,
        team,
    }
}

function* loginSaga(action) {
    try {
        // debounce by 1000ms
        yield delay(1000)
        const {pin, nickname, playerKey, team} = action
        const apiUrl = `/api/player/games/${pin}/login`
        const player = {
            nickname: nickname,
            player_key: playerKey, // eslint-disable-line camelcase
        }
        // make api call
        const response = yield call(request.post, apiUrl, {player})
        yield put(setGame(humps.camelizeKeys(response.data)))
        yield put(
            setPlayer({nickname: nickname, playerKey: playerKey, team: team})
        )
        yield put(setAppState(PLAYING))
        // setup Pusher callbacks
        setupPusherForPlayer(pin, nickname, playerKey)
    } catch (error) {
        console.log("error", error)
        deleteCookie("play.glickon.com.game")
        yield put(setAppState(ENTER_PIN))
    }
}

export function* loginWatcher() {
    yield takeLatest(LOGIN, loginSaga)
}
