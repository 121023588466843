import * as request from "axios"
import humps from "humps"
import constants from "appConstants"
import { call, put, takeLatest, select } from "redux-saga/effects"
import { GET_QUESTION_RESULT } from "sagas/owner/actions/actionTypes"
import { setQuestionResult } from "sagas/owner/actions/playing/setQuestionResult"
import { reportError } from "sagas/global/actions/error"

export function getQuestionResult(pin, ownerKey) {
    return {
        type: GET_QUESTION_RESULT,
        pin,
        ownerKey
    }
}

function* getQuestionResultSaga(action) {
    try {
        const { data: { info: { gameMode } } } = yield select((state) => state.global.game)
        const apiUrl = `/api/owner/games/${action.pin}/${action.ownerKey}/show_question_results`
        const question = yield call(request.post, apiUrl)
        yield put(setQuestionResult(humps.camelizeKeys(question.data)))
    } catch (error) {
        console.log("error", error)
        reportError(error)
    }
}

export function* getQuestionResultWatcher() {
    yield takeLatest(GET_QUESTION_RESULT, getQuestionResultSaga)
}
