import React from "react"
import {connect} from "react-redux"
import SurveyEnd from "views/owner/surveyEnd/components/surveyEnd"

let Container = ({...props}) => <SurveyEnd {...props}/>

const mapStateToProps = (state) => ({
    game: state.global.game.data,
    results: state.global.results
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
