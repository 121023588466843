import humps from "humps"
import {delay} from "redux-saga/effects"
import {put, takeLatest, fork, take, cancel} from "redux-saga/effects"
import {SET_QUESTION, STOP_COUNTDOWN} from "sagas/player/actions/actionTypes"
import {
    stopCountdown,
    startCountdown,
} from "sagas/player/actions/playing/countdown"
import {reportError} from "sagas/global/actions/error"

const _SET_QUESTION = "_SET_QUESTION"
export function setQuestion(data) {
    return {
        type: _SET_QUESTION,
        data: data,
    }
}

export function setQuestionAction(data) {
    return {
        type: SET_QUESTION,
        data: data,
    }
}

function* setQuestionSaga(action) {
    try {
        yield put(stopCountdown()) // stop previous countdown
        yield put(setQuestionAction(humps.camelizeKeys(action.data)))
        yield delay(5000) // time of the question preview
        let timer = yield fork(startCountdown)
        yield take(STOP_COUNTDOWN)
        yield cancel(timer)
    } catch (error) {
        console.log("error", error)
        reportError(error)
    }
}

export function* setQuestionWatcher() {
    yield takeLatest(_SET_QUESTION, setQuestionSaga)
}
