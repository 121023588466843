import React, { Component } from "react"
import { getImageUrl } from "utils"
import { TweenMax, Back } from "gsap"
import PlayButton from "commonComponents/playButton"
import Answers from "views/owner/common/answers"
import Logo from "views/owner/common/logo"
import Progress from "views/owner/common/progress"

const zoom = (delay = 0) => ({
    scale: 0,
    ease: Back.easeOut.config(1),
    delay: delay
})

class QuestionResults extends Component {

    constructor(props) {
        super(props)
        this.state = { isNextQuestionDisabled: false }
    }

    componentDidMount() {
        this.setupAnimations()
        this.onKeyPress = this._onKeyPress.bind(this)
        document.body.addEventListener("keypress", this.onKeyPress)
    }

    componentWillUnmount() {
        document.body.removeEventListener("keypress", this.onKeyPress)
    }

    setupAnimations() {
        if (this.resultsRef) {
            TweenMax.from(this.resultsRef, 0.25, zoom(0.5))
        }
    }

    getNextQuestion() {
        const getNextQuestion = this.props.getNextQuestion
        setTimeout(getNextQuestion, 250)
        this.setState({ isNextQuestionDisabled: true })
    }

    _onKeyPress(e) {
        if (e.keyCode === 13) { // keyCode for "Enter"
            this.getNextQuestion()
        }
    }

    get numberOfAnswers() {
        const { liveAnswers, isSurvey } = this.props
        if (isSurvey) {
            return liveAnswers.totalAnswers
        } else {
            return parseInt(liveAnswers.correctAnswers, 10) + parseInt(liveAnswers.wrongAnswers, 10)
        }
    }

    get results() {
        const { result, isSurvey } = this.props
        return isSurvey ? null : (
            <div id="results-main-wrapper" ref={div => this.resultsRef = div}>
                <div className="result-wrapper">
                    <div id="number-of-correct-answers">{result.correctAnswers}</div>
                    <img id="correct-icon-wrapper" src={getImageUrl("like.png")} alt="" />
                </div>
                <div className="result-wrapper">
                    <img id="wrong-icon-wrapper" src={getImageUrl("dislike.png")} alt="" />
                    <div id="number-of-wrong-answers">{result.wrongAnswers}</div>
                </div>
            </div>
        )
    }

    render() {
        let { currentQuestion, result, isSurvey, top, isSurveyReview, game, waitingForPlayers, currentQuestionNumber, liveAnswers } = this.props
        let numberOfAnswers = liveAnswers ? this.numberOfAnswers : 0
        return (
            <article className="h-screen w-full relative">
                <section className="flex h-screen">
                    <section className="w-[40%] flex place-content-center place-items-center border-r-gray-100 border-r">
                        <div className="flex flex-col h-[70vh]">
                            <Logo game={game} start={false} showDefault={false}></Logo>
                            <div className="m-auto mt-6 md:mt-20 w-full flex flex-col">
                                <div className="flex justify-center items-center">
                                    {currentQuestionNumber + 1} of {Object.keys(game.data.questions).length}
                                </div>
                                <div className="mt-2 flex justify-center items-center">
                                    {Array.from('00:00')
                                        .map((digit, i) => { return <span key={i} className="text-gray-900 text-5xl">{digit}</span> })}
                                </div>
                                <div className="mt-2 md:mt-20 flex justify-center items-center">
                                    <Progress time={0} color={'#D0D5DD'}></Progress>
                                </div>
                            </div>
                            <div className="m-auto mt-6 md:mt-20 w-full justify-center flex">
                                <div className="flex justify-center items-center">
                                    <img className="mr-2" src={getImageUrl('users.svg')} alt="Logo" />
                                    <div className="flex items-center justify-center">
                                        <span className="mr-2 text-gray-400">{`${numberOfAnswers}`}</span>
                                        <span className="mr-2">/</span>
                                        <span className="mr-2">
                                            {`${waitingForPlayers.players.numberOfPlayers} ${waitingForPlayers.players.numberOfPlayers === 1 ? 'risposta' : 'risposte'}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="w-[60%] flex place-content-center place-items-center glickon_bg_question" style={{
                        backgroundImage: `url("${getImageUrl('bg.svg')}")`,
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        <div className="w-[80%]">
                            <Answers
                                question={currentQuestion}
                                animated={false}
                                answers={currentQuestion.answers}
                                result={result}
                                isSurvey={isSurvey}
                                isSurveyReview={isSurveyReview}
                                showCorrectAnswer>
                                {isSurveyReview ? null : (
                                    <div id="question-results-play-button">
                                        <PlayButton onClick={this.getNextQuestion.bind(this)} color={'#84CE67'} />
                                    </div>
                                )}
                            </Answers>
                        </div>
                    </section>
                </section>
                <section className="bottom absolute bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-[70%] m-auto flex items-center justify-between">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                        <button disabled={this.state.isNextQuestionDisabled} onClick={() => { this.getNextQuestion() }} className="flex items-center justify-center glickon_button_play bg-black rounded-lg p-2 text-white">
                            <span>Avanti</span>
                            <img className="ml-2" src={getImageUrl('arrow-right.svg')} alt="Arrow" />
                        </button>
                    </div>
                </section>
            </article>
        )
    }
}

export default QuestionResults
