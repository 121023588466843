import {RESET_SCORE} from "sagas/player/actions/actionTypes"

/**
 * Action called player/playing component to reset score from the previous result
 */
export function resetScore() {
    return {
        type: RESET_SCORE
    }
}
