import {SET_PLAYER_LEADERBOARD} from "sagas/player/actions/actionTypes"

/**
 * Action called (only) by saga to set player leaderboard
 */
export function setPlayerLeaderboard(data) {
    return {
        type: SET_PLAYER_LEADERBOARD,
        data
    }
}
