import {WAITING_FOR_PLAYERS} from "appStates/owner"
import {O_SET_APP_STATE, SET_GAME} from "sagas/owner/actions/actionTypes"
import {O_GAME_LEADERBOARD, O_SURVEY_END} from "appStates/owner"
import constants from "appConstants"

const GAME_ENDED = 2

export function appStateOwner(state = WAITING_FOR_PLAYERS, action) {
    switch (action.type) {
        case O_SET_APP_STATE:
            return action.state

        case SET_GAME:
            // if the game is already ended then show the leaderboard/surveyend view directly
            if (action.data.status.status === GAME_ENDED) {
                if (action.data.info.gameMode === constants.MODE_SURVEY) {
                    return O_SURVEY_END
                } else {
                    return O_GAME_LEADERBOARD
                }
            } else {
                return state
            }

        default:
            return state
    }
}
