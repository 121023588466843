import React from "react"
import {getImageUrl} from "utils"
import "commonComponents/playButton/index.css"

export default ({color, onClick, text, arrowLeft, disabled, smallFont}) => {
    const _onClick = (event) => {
        if (onClick) {
            event.preventDefault()
            onClick()
        }
    }
    return (
        <button id="play-button" style={{backgroundColor: color, opacity: `${disabled ? 0.2 : 1}`, cursor: `${disabled ? "default" : "pointer"}`}} onClick={_onClick}>
            {text ? <div className={`${smallFont ? "button-text-small" : "button-text"}`}>{text}</div> : null}
            <img src={arrowLeft ? getImageUrl("arrow_left.png") : getImageUrl("arrow_right.png")} alt=""/>
        </button>
    )
}
