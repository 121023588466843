import React, { Component } from "react"
import { TweenMax, Elastic } from "gsap"
import GamePin from "views/player/common/gamePin"
import { getImageUrl, deleteCookie, remToPx } from "utils"
import Logo from "views/owner/common/logo"

class PlayerSurveyEnd extends Component {
    componentDidMount() {
        deleteCookie("play.glickon.com.game")
        this.setupAnimations()
    }

    setupAnimations() {
        if (this.firstRef) {
            TweenMax.to(this.firstRef, 1, { y: remToPx(-120), ease: Elastic.easeOut.config(0.4, 0.35) })
        }
    }

    get title() {
        return this.props.endTitle ? this.props.endTitle : "Grazie mille!"
    }

    get text() {
        return this.props.endText ? (
            <div id="thank-you-message" dangerouslySetInnerHTML={{ __html: this.props.endText }} />
        ) : (
            <div id="thank-you-message">
                Ti ringraziamo per aver partecipato. Per noi è molto importante la tua opinione per migliorare il tuo lavoro.
            </div>
        )
    }

    render() {
        let game = {
            data: {
                info: {
                    ...this.props.game.data
                }
            }
        }
        return <article className="w-full h-screen place-content-center place-items-center flex relative">
            <section className="flex flex-col h-[70vh]">
                <div>
                    <Logo game={game} showDefault={false} hidePin={true}></Logo>
                </div>
                <div className="mt-6">
                    <p className="gt text-4xl text-gray-900 m-auto text-center">{this.title}</p>
                    <p className="text-sm text-gray-400 m-auto text-center w-[80%] mt-4">{this.text}</p>
                </div>
            </section>
            <section className="fixed bottom-0 left-0 w-full flex justify-center m-auto items-center p-2">
                <GamePin />
            </section>
        </article>
    }
}

export default PlayerSurveyEnd
