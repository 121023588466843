import {ENTER_PIN} from "appStates/player"
import {SET_APP_STATE} from "sagas/player/actions/actionTypes"

export function appStatePlayer(state = ENTER_PIN, action) {
    switch (action.type) {
        case SET_APP_STATE:
            return action.state

        default:
            return state
    }
}
