import React, { Component } from "react"
import { TweenMax, Back } from "gsap"
import { getImageUrl } from "utils"
import classNames from "classnames"

const answersDelay = 5
const answersStyles = [
    {
        letter: 'A',
        colorClass: 'bg-brandy-bear-200'
    },
    {
        letter: 'B',
        colorClass: 'bg-primary-200'
    },
    {
        letter: 'C',
        colorClass: 'bg-indiviolet-sunset-200'
    },
    {
        letter: 'D',
        colorClass: 'bg-warning-100'
    }
]

const zoom = (delay = 0) => ({
    scale: 0,
    ease: Back.easeOut.config(1),
    delay: delay
})

class Answers extends Component {

    componentDidMount() {
    }

    render() {
        const { isSurvey, question, answers, result } = this.props
        return (
            <section>
                <div>
                    {question.imageUrl &&
                        <div className="w-full flex items-center">
                            <img className="max-h-[25vh] rounded-tr-lg rounded-br-lg rounded-bl-lg" src={question.imageUrl} alt="Question image" />
                        </div>
                    }
                    <div className="mt-6">
                        <p className="text-gray-900 text-[24px] gt">{question.text}</p>
                    </div>
                    <div className="mt-6">
                        {
                            answers.map((answer, index) => {
                                // Various IF
                                if (!answer.text) {
                                    return null
                                }
                                let stat
                                if (result) {
                                    stat = result[`answer${index + 1}Stats`]
                                }
                                if (isSurvey) {
                                    if (result) {
                                        return <div style={{
                                            background: `linear-gradient(90deg, #F2F4F7 ${stat}%, #F9FAFB ${stat}%)`
                                        }} className={classNames([answersStyles[index].colorClass, 'mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg flex justify-between items-center'])} key={index}>
                                            <span className="text-gray-900 text-[16px] font-normal">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                                            <span className="text-gray-400 text-xs font-normal">{stat}%</span>
                                        </div>
                                    } else {
                                        return <div className='mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg bg-[#EAECF0]' key={index}>
                                            <span className="text-gray-900 text-[16px] font-normal">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                                        </div>
                                    }
                                } else {
                                    if (result) {
                                        return <div style={{
                                            background: answer.isCorrect ? `linear-gradient(90deg, #84CE67 ${stat}%, #F2F4F7 ${stat}%)` : `linear-gradient(90deg, #D0D5DD ${stat}%, #F2F4F7 ${stat}%)`,
                                            border: answer.isCorrect && stat === '0.0' ? '3px solid #84CE67' : ''
                                        }} className={classNames([answersStyles[index].colorClass, 'mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg flex justify-between items-center'])} key={index}>
                                            <span className="text-gray-900 text-[16px] font-normal">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                                            <span className="text-gray-400 text-xs font-normal">{stat}%</span>
                                        </div>
                                    } else {
                                        return <div className={classNames([answersStyles[index].colorClass, 'mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg'])} key={index}>
                                            <span className="text-gray-900 text-[16px] font-normal">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                                        </div>
                                    }
                                }
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default Answers
