import { SET_QUESTION_RESULT } from "sagas/owner/actions/actionTypes"

/**
 * Action called (only) by saga to set question results
 */
export function setQuestionResult(data) {
    return {
        type: SET_QUESTION_RESULT,
        data,
    }
}
