import React, { Component } from "react"
import { TweenMax, Power2 } from "gsap"
import Podium from "commonComponents/podium"
import Logo from "views/owner/common/logo"
import { getImageUrl } from "utils"
import JSConfetti from 'js-confetti'

class GameLeaderboard extends Component {

    componentDidMount() {
        const jsConfetti = new JSConfetti()
        const { pin, ownerKey, getGameLeaderboard } = this.props
        getGameLeaderboard(pin, ownerKey)
        setTimeout(() => {
            jsConfetti.addConfetti({
                confettiColors: [
                    '#FFCDC2', '#92C6EA', '#CBE2AB', '#FAD195'
                ],
            })
        }, 600)
    }

    componentDidUpdate(prevProps) {
        if (this.props.gameLeaderboard !== prevProps.gameLeaderboard) {
            // this.setupAnimations()
        }
    }

    setupAnimations() {
        TweenMax.from(this.bottomScoreRef, 0.75, {
            ease: Power2.easeOut,
            y: 16 * 20,
        })
    }

    get podium() {
        const {
            gameLeaderboard: { leaderboard },
        } = this.props
        return leaderboard?.length > 0 ? (
            <Podium
                winner={leaderboard[0]}
                runnerUp={leaderboard[1]}
                thirdPlace={leaderboard[2]}
                big
            />
        ) : null
    }

    render() {
        const { gameLeaderboard, game } = this.props
        if (gameLeaderboard) {
            return (
                <article className="w-full h-screen">
                    <section className="w-[60%] m-auto">
                        <div className="p-8">
                            <Logo game={game} start={false} hidePin={true} showDefault={false}></Logo>
                        </div>
                        <div className="mt-4 max-h-[60vh] overflow-hidden">
                            {gameLeaderboard.leaderboard.length ? gameLeaderboard.leaderboard.map(
                                (player, index) => {
                                    return <div key={index} className="w-[80%] m-auto flex justify-between items-center glickon_leaderboard_border mb-2 pb-2 relative">
                                        {
                                            (index + 1) === 1 && <img className="mr-2 absolute top-[1px] left-[-40px]" src={getImageUrl('first.svg')} alt="Trophy" />
                                        }
                                        {
                                            (index + 1) === 2 && <img className="mr-2 absolute top-[1px] left-[-40px]" src={getImageUrl('second.svg')} alt="Trophy" />
                                        }
                                        {
                                            (index + 1) === 3 && <img className="mr-2 absolute top-[1px] left-[-40px]" src={getImageUrl('third.svg')} alt="Trophy" />
                                        }
                                        <div className="w-[70%] lowercase">{index + 1}  &nbsp; {player.entity}</div>
                                        <div className="w-[20%] flex justify-end items-center">
                                            <div className="glickon_badge_pt">{player.score}pt</div>
                                        </div>
                                    </div>
                                }
                            ) :
                                <div className="w-full m-auto p-10 flex justify-center">
                                    <div className="text-center text-3xl text-gray-900 gt">
                                        La leaderboard è vuota, non erano presenti giocatori
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                    <section className="bottom absolute bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                        <div className="w-full m-auto flex items-center justify-center">
                            <img src={getImageUrl('powered.svg')} alt="Logo" />
                        </div>
                    </section>
                </article>
            )
        } else {
            return null
        }
    }
}

export default GameLeaderboard
