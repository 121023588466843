export default {
    option: (provided, state) => {
        const { isSelected, isFocused } = state
        return {
            ...provided,
            cursor: "pointer",
            fontFamily: "Calibre",
            color: "#444444",
            backgroundColor: isSelected || isFocused ? "#f4f5f7" : null,

            ":active": {
                backgroundColor: null,
            },
        }
    },
    indicatorSeparator: (provided, state) => {
        const { hasValue, selectProps } = state
        const { isClearable } = selectProps
        return {
            ...provided,
            display: isClearable && hasValue ? "block" : "none",
        }
    },
    clearIndicator: (provided, state) => {
        const { isFocused } = state
        return {
            ...provided,
            color: isFocused ? "#444444" : "#777777",
            cursor: "pointer",
            fontSize: "1.25rem",
        }
    },
    dropdownIndicator: (provided, state) => {
        const { isFocused } = state
        return {
            ...provided,
            cursor: "pointer",
            color: isFocused ? "#444444" : "#777777",
            fontSize: "1.5rem",
        }
    },
    // eslint-disable-next-line
    noOptionsMessage: (provided, state) => {
        return {
            ...provided,
            fontFamily: "Calibre",
            color: "#d0d0d0",
            textAlign: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "calc(100% - 0.5rem)",
        }
    },
    // eslint-disable-next-line
    placeholder: (provided, state) => {
        return {
            ...provided,
            fontFamily: "Calibre",
            color: "#777777",
            textAlign: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
        }
    },
    // eslint-disable-next-line
    menu: (provided, state) => {
        return {
            ...provided,
            borderRadius: "8px",
            zIndex: 10,
        }
    },
    // eslint-disable-next-line
    menuList: (provided, state) => {
        return {
            ...provided,
            maxHeight: "11rem",
        }
    },
    control: () => {
        return {
            border: `1px solid #d0d5dd`,
            borderRadius: "8px",
            height: "3rem",
            display: "flex",
            fontFamily: "sans-serif",
            fontSize: "unset",
            backgroundColor: "#ffffff",
            padding: "5px 15px",
            outline: "none",
            color: "unset",
        }
    },
}
