import React, { Component } from "react"
import { getImageUrl } from "utils"
import { translate } from "utils"

class Logo extends Component {

    render() {
        let { game, start, hidePin } = this.props
        let url = getImageUrl('glickon_live.svg')
        if (game && game.data.info.logoUrl) {
            url = game.data.info.logoUrl
        }
        const isDefault = url.includes('glickon_live.svg')
        return (
            <div className="m-auto mt-0 flex flex-col">
                {isDefault ?
                    <div className="flex justify-center items-center glickon_logo_wrapper_default" >
                        <img className="w-[80%]" src={url} alt="" />
                    </div> :
                    <div className="flex justify-center items-center glickon_logo_wrapper" style={{ backgroundImage: `url("${getImageUrl('circle_logo.svg')}")` }}>
                        <img className="w-[50%] rounded-full" src={url} alt="Glickon Logo" />
                    </div>
                }
                {!hidePin &&
                    <div>
                        {start ?
                            <div className="mt-6 md:mt-20 w-[70%] m-auto">
                                <p className="text-center text-gray-900 text-xl font-light">{translate("o-join-game-title")}</p>
                                <p className="w-full flex justify-evenly">
                                    {Array.from(game.data.info.pin.toString()).map((digit, i) => { return <span key={i} className="text-gray-900 mt-4 text-5xl">{digit}</span> })}
                                </p>
                            </div> :
                            <div className="mt-6 flex justify-center">
                                <div className="glickon_badge">
                                    Game Pin: {game.data.info.pin}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default Logo
