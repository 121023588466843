import {TweenMax, Elastic} from "gsap"
import React, {Component} from "react"
import "commonComponents/podium/index.css"

const zoom = (delay = 0) => ({
    scale: 0,
    ease: Elastic.easeOut.config(1, 0.55),
    delay: delay,
})
class Podium extends Component {
    componentDidMount() {
        this.setupAnimations()
    }

    setupAnimations() {
        // TODO TweenMax.to(this.firstRef, 1, {y: -480, ease: Elastic.easeOut.config(0.4, 0.2)})
        TweenMax.from(this.firstRef, 0.7, zoom(0.5))
        // this is corner case, when there are less than 3 players, Tween cannot reference undefined object
        if (this.secondRef) {
            TweenMax.from(this.secondRef, 0.7, zoom(0.55))
        }
        if (this.thirdRef) {
            TweenMax.from(this.thirdRef, 0.7, zoom(0.6))
        }
    }

    render() {
        const {winner, runnerUp, thirdPlace, big = false} = this.props
        let winnerScore = winner && (
            <div
                ref={(div) => (this.firstRef = div)}
                className={`podium-score-wrapper${
                    big ? "-big" : ""
                } winner-score-wrapper${big ? "-big" : ""}`}
            >
                <div
                    className={`${
                        big ? "score-icon-big" : "score-icon"
                    } winner-score-icon`}
                >
                    1
                </div>
                <div className={`${big ? "podium-name-big" : "podium-name"}`}>
                    {winner.entity}
                </div>
                <div
                    className={`${big ? "podium-points-big" : "podium-points"}`}
                >
                    {winner.score.toFixed(1)}pt
                </div>
            </div>
        )
        let runnerUpScore = runnerUp && (
            <div
                ref={(div) => (this.secondRef = div)}
                className={`podium-score-wrapper${
                    big ? "-big" : ""
                } runner-up-score-wrapper${big ? "-big" : ""}`}
            >
                <div
                    className={`${
                        big ? "score-icon-big" : "score-icon"
                    } runner-up-score-icon`}
                >
                    2
                </div>
                <div className={`${big ? "podium-name-big" : "podium-name"}`}>
                    {runnerUp.entity}
                </div>
                <div
                    className={`${big ? "podium-points-big" : "podium-points"}`}
                >
                    {runnerUp.score.toFixed(1)}pt
                </div>
            </div>
        )
        let thirdPlaceScore = thirdPlace && (
            <div
                ref={(div) => (this.thirdRef = div)}
                className={`podium-score-wrapper${
                    big ? "-big" : ""
                } third-place-score-wrapper${big ? "-big" : ""}`}
            >
                <div
                    className={`${
                        big ? "score-icon-big" : "score-icon"
                    } third-place-score-icon`}
                >
                    3
                </div>
                <div className={`${big ? "podium-name-big" : "podium-name"}`}>
                    {thirdPlace.entity}
                </div>
                <div
                    className={`${big ? "podium-points-big" : "podium-points"}`}
                >
                    {thirdPlace.score.toFixed(1)}pt
                </div>
            </div>
        )

        return (
            <div id="podium-wrapper">
                {winnerScore}
                {runnerUpScore}
                {thirdPlaceScore}
            </div>
        )
    }
}

export default Podium
