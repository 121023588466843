import {SET_GAME, SET_GAME_STATE, SET_PLAYER} from "sagas/player/actions/actionTypes"
import {FETCHED} from "sagas/player/actions/game"

export function game(state = {state: null, data: null, player: null}, action) {
    switch (action.type) {
        case SET_GAME:
            return {
                state: FETCHED,
                data: action.data,
                player: state.player
            }

        case SET_GAME_STATE:
            return {
                state: action.state,
                data: state.data,
                player: state.player
            }

        case SET_PLAYER:
            return {
                state: state.state,
                data: state.data,
                player: action.data
            }

        default:
            return state
    }
}
