import {put, select, call, delay} from "redux-saga/effects"
import * as actionTypes from "sagas/player/actions/actionTypes"
import {PLAYING} from "appStates/player"

export function stopCountdown() {
    // TODO! call this method when an error is raised
    return {
        type: actionTypes.STOP_COUNTDOWN,
    }
}

export function setTimeElapsed(timeElapsed) {
    return {
        type: actionTypes.SET_TIME_ELAPSED,
        timeElapsed,
    }
}

export function* startCountdown() {
    let {appStatePlayer} = yield select((state) => state.global)
    while (appStatePlayer === PLAYING) {
        yield delay(1000)
        let {question} = yield select((state) => state.views.playing)
        if (question.currentQuestion) {
            if (question.timeElapsed >= question.currentQuestion.time) {
                yield put(stopCountdown())
            } else {
                yield put(setTimeElapsed(question.timeElapsed + 1))
            }
        }
    }
}
