import * as request from "axios"
import { call, put, takeLatest } from "redux-saga/effects"
import { SEND_ANSWER } from "sagas/player/actions/actionTypes"
import { setScore } from "sagas/player/actions/playing/setScore"
import { reportError } from "sagas/global/actions/error"

export function sendAnswer(pin, nickname, playerKey, answer) {
    return {
        type: SEND_ANSWER,
        pin,
        nickname,
        playerKey,
        answer
    }
}

function* sendAnswerSaga(action) {
    try {
        const apiUrl = `/api/player/games/${action.pin}/send_answer`
        const result = yield call(request.post, apiUrl, {
            player: { nickname: action.nickname, player_key: action.playerKey }, answer: action.answer
        } // eslint-disable-line camelcase
        )
        if (result.status === 200) {
            yield put(setScore(action.answer, result.data.current_score))
        }
    } catch (error) {
        console.log("error", error)
        reportError(error)
    }
}

export function* sendAnswerWatcher() {
    yield takeLatest(SEND_ANSWER, sendAnswerSaga)
}
