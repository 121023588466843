import Checkbox from "commonComponents/checkbox"

export default ({ text, setChecked, checked }) => {
    // note: when checked is undefined no checkbox should be checked
    return (
        <div className="w-full m-auto">
            <div className="mb-3 text-sm text-gray-400 font-light">{text}</div>
            <div className="flex justify-between items-center w-full">
                <div className="checkbox-wrapper w-[48%]">
                    <Checkbox
                        text={'ACCONSENTO'}
                        showError={false}
                        value={checked === true}
                        onCheck={() =>
                            setChecked(true)
                        }
                    />
                </div>
                <div className="checkbox-wrapper w-[48%]">
                    <Checkbox
                        text={'NON ACCONSENTO'}
                        showError={false}
                        value={checked === false}
                        onCheck={() =>
                            setChecked(false)
                        }
                    />
                </div>
            </div>
        </div>
    )
}
