import React from "react"
import { connect } from "react-redux"
import PlayerLeaderboard from "views/player/playerLeaderboard/components/playerLeaderboard"
import { getPlayerLeaderboard } from "sagas/player/actions/playerLeaderboard"

let Container = ({ ...props }) => <PlayerLeaderboard {...props} />

const mapStateToProps = (state) => ({
    playerLeaderboard: state.views.playerLeaderboard.playerLeaderboard,
    podium: state.views.playerLeaderboard.podium,
    pin: state.global.game.data.pin,
    nickname: state.global.game.player.nickname,
    gameMode: state.global.game.data.gameMode,
    myTeam: state.global.game.player.team
})
const mapDispatchToProps = (dispatch) => ({
    getPlayerLeaderboard: (pin, nickname) => dispatch(getPlayerLeaderboard(pin, nickname))
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
