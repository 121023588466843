import humps from "humps"
import {SET_PLAYERS} from "sagas/owner/actions/actionTypes"

/**
 * Action triggered by players-joined event
 */
export function setPlayers(data) {
    return {
        type: SET_PLAYERS,
        data: humps.camelizeKeys(data)
    }
}
