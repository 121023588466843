import {SET_GAME_LEADERBOARD} from "sagas/owner/actions/actionTypes"

/**
 * Action called (only) by saga to set game leaderboard
 */
export function setGameLeaderboard(data) {
    return {
        type: SET_GAME_LEADERBOARD,
        data
    }
}
