import {SET_QUESTION} from "sagas/owner/actions/actionTypes"

/**
 * Action called (only) by saga to set game info
 */
export function setQuestion(data) {
    return {
        type: SET_QUESTION,
        data,
    }
}
