import React, { Component } from "react"
import PlayButton from "commonComponents/playButton"
import Loading from "commonComponents/loading"
import { translate, preloadImages } from "utils"
import { getImageUrl } from "utils"
import Logo from "views/owner/common/logo"
class WaitingForPlayers extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { pin, ownerKey, getGame } = this.props
        getGame(pin, ownerKey)
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.game.data && this.props.game.data.questions) {
            let gameImages = this.props.game.data.questions.map(question => question.imageUrl)
            preloadImages(gameImages)
        }
        // Scroll to latest added player
        let latestPlayer = this.props.waitingForPlayers.players.latestJoinedPlayers.length
        setTimeout(() => {
            if (latestPlayer >= 4) {
                console.log()
            }
            if (document.querySelector(`#player-${latestPlayer - 2}`)) {
                document.querySelector(`#player-${latestPlayer - 2}`).style.transition = '200ms'
                document.querySelector(`#player-${latestPlayer - 2}`).style.opacity = 0.2
            }
            if (document.querySelector(`#player-${latestPlayer - 1}`)) {
                document.querySelector(`#player-${latestPlayer - 1}`).style.transition = '200ms'
                document.querySelector(`#player-${latestPlayer - 1}`).style.opacity = 0.5
            }
            if (document.querySelector(`#player-${latestPlayer}`)) {
                document.querySelector(`#player-${latestPlayer}`).scrollIntoView({ behavior: "smooth" })
            }
        }, 300)
    }

    startGame() {
        this.props.startGame()
    }

    render() {
        const { game } = this.props
        return game.data ? (
            <article className="h-screen w-full relative">
                <section className="flex h-screen">
                    <section className="w-[40%] flex place-content-center place-items-center border-r-gray-100 border-r">
                        <div className="flex flex-col h-[70vh]">
                            <Logo game={game} start={true} showDefault={false}></Logo>
                            <div className="w-full justify-center flex">
                                <div className="flex justify-center items-center">
                                    <img className="mr-2" src={getImageUrl('users.svg')} alt="Logo" />
                                    <span>
                                        {`${this.props.waitingForPlayers.players.numberOfPlayers} ${this.props.waitingForPlayers.players.numberOfPlayers === 1 ? 'utente' : 'utenti'}`}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-2 w-[50%] m-auto h-[9vh] overflow-hidden">
                                <div className="h-[10vh]">&nbsp;</div>
                                {
                                    this.props.waitingForPlayers.players.latestJoinedPlayers.reverse().map((player, index) => (
                                        <div className="text-center h-[3vh]" key={`player-${index}`} id={`player-${index + 1}`}>{player}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                    <section className="w-[60%] flex place-content-center place-items-center">
                        <div className="w-[80%] m-auto">
                            <div className="gt text-4xl">{game.data.info.name}</div>
                            <div className="mt-6 text-gray-400 font-light" dangerouslySetInnerHTML={{ __html: game.data.info.description }} />
                            <button onClick={this.startGame.bind(this)} className="mt-6 glickon_button">
                                <span>Iniziamo</span>
                                <img src={getImageUrl('arrow-right.svg')} alt="Arrow" />
                            </button>
                        </div>
                    </section>
                </section>
                <section className="bottom absolute bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div>
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                    </div>
                </section>
            </article>
        ) : <Loading />
    }
}

export default WaitingForPlayers
