import {getGameWatcher} from "sagas/owner/actions/game"
import {getNextQuestionWatcher} from "sagas/owner/actions/playing"
import {getQuestionResultWatcher} from "sagas/owner/actions/playing"
import {getGameLeaderboardWatcher} from "sagas/owner/actions/gameLeaderboard"

export default function runOwnerSagas(sagaMiddleware) {
    sagaMiddleware.run(getGameWatcher)
    sagaMiddleware.run(getNextQuestionWatcher)
    sagaMiddleware.run(getQuestionResultWatcher)
    sagaMiddleware.run(getGameLeaderboardWatcher)
}
