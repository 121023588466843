export const GET_GAME = "O_GET_GAME"
export const SET_GAME = "O_SET_GAME"
export const SET_QUESTION = "O_SET_QUESTION"
export const GET_NEXT_QUESTION = "O_GET_NEXT_QUESTION"
export const SET_PLAYERS = "O_SET_PLAYERS"
export const O_SET_APP_STATE = "O_SET_APP_STATE"
export const SET_ANSWERS = "O_SET_ANSWERS"
export const RESET_ANSWERS = "O_RESET_ANSWERS"
export const GET_QUESTION_RESULT = "O_GET_QUESTION_RESULT"
export const SET_QUESTION_RESULT = "O_SET_QUESTION_RESULT"
export const RESET_QUESTION_RESULT = "O_RESET_QUESTION_RESULT"
export const GET_GAME_LEADERBOARD = "O_GET_GAME_LEADERBOARD"
export const SET_GAME_LEADERBOARD = "O_SET_GAME_LEADERBOARD"
export const O_STOP_COUNTDOWN = "O_STOP_COUNTDOWN"
export const O_SET_TIME_ELAPSED = "O_SET_TIME_ELAPSED"

