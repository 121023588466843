import React from "react"
import {connect} from "react-redux"
import Registration from "views/player/registration/components/registration"
import {joinGame, setGameState} from "sagas/player/actions/game"

let Container = ({...props}) => <Registration {...props} />

const mapStateToProps = (state) => ({
    game: state.global.game,
})
const mapDispatchToProps = (dispatch) => ({
    joinGame: (
        nickname,
        first_name,
        last_name,
        email,
        phone,
        optionalFlagConsent,
        birthday,
        specialization,
        myGiGroupConsent
    ) =>
        dispatch(
            joinGame(
                nickname,
                first_name,
                last_name,
                email,
                phone,
                optionalFlagConsent,
                birthday,
                specialization,
                myGiGroupConsent
            )
        ),
    setGameState: (state) => dispatch(setGameState(state)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
