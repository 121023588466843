import * as request from "axios"
import {call, put, takeLatest, select} from "redux-saga/effects"
import {GET_PLAYER_LEADERBOARD} from "sagas/player/actions/actionTypes"
import {setPlayerLeaderboard} from "sagas/player/actions/playerLeaderboard/setPlayerLeaderboard"
import {reportError} from "sagas/global/actions/error"
import {deleteCookie} from "utils"
import constants from "appConstants"

export function getPlayerLeaderboard(pin, nickname) {
    return {
        type: GET_PLAYER_LEADERBOARD,
        pin,
        nickname
    }
}

function* getPlayerLeaderboardSaga(action) {
    try {
        const game = yield select((state) => state.global.game)
        // const apiUrl = `/api/player/games/${action.pin}/leaderboard/${gameData.gameMode === MODE_GAME_FOR_TEAMS ? action.nickname}`
        const player = game.player
        const apiUrl = `/api/player/games/${action.pin}/leaderboard/${game.data.gameMode === constants.MODE_GAME_FOR_TEAMS ? player.team : player.nickname}`
        const response = yield call(request.get, apiUrl)
        yield put(setPlayerLeaderboard(response.data))
        deleteCookie("play.glickon.com.game")
    } catch (error) {
        console.log("error", error)
        reportError(error)
    }
}

export function* getPlayerLeaderboardWatcher() {
    yield takeLatest(GET_PLAYER_LEADERBOARD, getPlayerLeaderboardSaga)
}
