import React from "react"

export default ({ text, onCheck, showError, value }) => (
    <div className="glickon_check_wrapper">
        <label className="label">
            <div className="text_wrapper ">
                <p className="text-sm text-gray-400 font-light">
                    {text}
                </p>
            </div>
            <div className="checkmark_wrapper">
                <span
                    className={`checkmark ${showError && !value
                        ? "error-style"
                        : value
                            ? "checked"
                            : null
                        } block`}
                    onClick={() => onCheck()}
                >{value ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                    : null}</span>
            </div>
            <input type="checkbox" className="hidden" />
        </label>
    </div>
)
