import * as React from 'react';
import * as ReactDOM from 'react-dom';
import useDigitInput from 'react-digit-input';

export default function Digits({ onDigit }) {
    const [value, onChange] = React.useState('');
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 6,
        value,
        onChange,
    });

    React.useEffect(() => {
        onDigit(value)
    }, [value]);

    return (
        <div>
            <div className="glickon_input_group">
                <input inputMode="decimal" {...digits[0]} />
                <input inputMode="decimal" {...digits[1]} />
                <input inputMode="decimal" {...digits[2]} />
                <span className="hyphen" />
                <input inputMode="decimal" {...digits[3]} />
                <input inputMode="decimal" {...digits[4]} />
                <input inputMode="decimal" {...digits[5]} />
            </div>
        </div>
    );
}