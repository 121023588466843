import bugsnag from "bugsnag-js"

import React from "react"
import ReactDOM from "react-dom"
import { Provider, connect } from "react-redux"
import createPlugin from "bugsnag-react"
import i18next from "i18next"
import EnterPin from "views/player/enterPin"
import EnterNickname from "views/player/enterNickname"
import ChooseTeam from "views/player/chooseTeam"

import Playing from "views/player/playing"
import PlayerLeaderboard from "views/player/playerLeaderboard"
import PlayerSurveyEnd from "views/player/playerSurveyEnd"
import Registration from "views/player/registration"

import { getStore } from "store"
import playerReducers from "sagas/player/reducers"
import ownerReducers from "sagas/owner/reducers"
import { Helmet } from "react-helmet";

import {
    ENTER_NICKNAME,
    ENTER_PIN,
    PLAYING,
    PLAYER_LEADERBOARD,
    CHOOSE_TEAM,
    SURVEY_END,
    REGISTRATION,
} from "appStates/player"
import {
    WAITING_FOR_PLAYERS,
    O_PLAYING,
    O_GAME_LEADERBOARD,
    O_SURVEY_END,
} from "appStates/owner"
import registerServiceWorker from "registerServiceWorker"
import WaitingForPlayers from "views/owner/waitingForPlayers"
import PlayingOwner from "views/owner/playing"
import GameLeaderboard from "views/owner/gameLeaderboard"
import SurveyEnd from "views/owner/surveyEnd"
import { setupPusherForOwner } from "channels"
import runOwnerSagas from "sagas/owner/run"
import runPlayerSagas from "sagas/player/run"
import "index.scss"
import { setLanguage, setBugsnagInstance, getImageUrl } from "utils"
import translations from "translations"
import axios from "axios"
import FullScreen from "views/owner/common/full_screen"

axios.defaults.baseURL = "/live"

const bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_ID || "123",
    appVersion: process.env.APP_VERSION || "0.0.0",
})
// wrap entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.use(createPlugin(React))

let store, pin, ownerKey

// determine if the user is player or owner
const pathRegex = /\/games\/(\w+)\/(\w+)/
const path = window.location.pathname
const matches = path.match(pathRegex)

if (matches) {
    pin = matches[1]
    ownerKey = matches[2]
    store = getStore(ownerReducers, runOwnerSagas)
    setupPusherForOwner(pin, ownerKey)
} else {
    store = getStore(playerReducers, runPlayerSagas)
}

export const storeForScope = store // the same instance of store has to be used by pusher (in channels.js)

const language = document.documentElement.getAttribute("lang")
i18next.init({
    lng: language, // TODO
    resources: translations,
})
setLanguage(language)
setBugsnagInstance(bugsnagClient)

const PlayerApp = ({ appStatePlayer }) => {
    let content
    switch (appStatePlayer) {
        case ENTER_NICKNAME:
            content = <EnterNickname />
            break
        case REGISTRATION:
            content = <Registration />
            break
        case CHOOSE_TEAM:
            content = <ChooseTeam />
            break
        case PLAYING:
            content = <Playing pin={pin} />
            break

        case PLAYER_LEADERBOARD:
            content = <PlayerLeaderboard pin={pin} ownerKey={ownerKey} />
            break

        case SURVEY_END:
            content = <PlayerSurveyEnd />
            break

        case ENTER_PIN:
        default:
            content = <EnterPin />
    }
    return <div id="player-wrapper">{content}</div>
}

const OwnerApp = ({ appStateOwner }) => {
    let content, wrapperClass
    switch (appStateOwner) {
        case WAITING_FOR_PLAYERS:
            wrapperClass = "wrapper-static"
            content = <WaitingForPlayers pin={pin} ownerKey={ownerKey} />
            break

        case O_PLAYING:
            content = <PlayingOwner pin={pin} ownerKey={ownerKey} />
            break

        case O_GAME_LEADERBOARD:
            content = <GameLeaderboard pin={pin} ownerKey={ownerKey} />
            break
        case O_SURVEY_END:
            content = <SurveyEnd />
            break
        default:
            content = <WaitingForPlayers pin={pin} ownerKey={ownerKey} />
    }
    return (
        <div id="owner-wrapper" className={wrapperClass}>
            <FullScreen></FullScreen>
            {content}
        </div>
    )
}

const App = connect((state) => ({
    appStatePlayer: state.global.appStatePlayer,
    appStateOwner: state.global.appStateOwner,
    state: state,
}))(matches ? OwnerApp : PlayerApp)

const provider = (
    <ErrorBoundary>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Glickon Live</title>
            <link rel="shortcut icon" href={getImageUrl('favicon.ico')} />
        </Helmet>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>
)

// TODO add Sentry

ReactDOM.render(provider, document.getElementById("root"))

registerServiceWorker()
