import * as request from "axios"
import humps from "humps"
import {delay} from "redux-saga/effects"
import {call, put, takeLatest} from "redux-saga/effects"
import {GET_GAME} from "sagas/owner/actions/actionTypes"
import {setGame} from "sagas/owner/actions/game/setGame"
import {reportError} from "sagas/global/actions/error"

export function getGame(pin, ownerKey) {
    return {
        type: GET_GAME,
        pin,
        ownerKey,
    }
}

function* getGameSaga(action) {
    try {
        // debounce by 500ms
        yield delay(500)
        const apiUrl = `/api/owner/games/${action.pin}/${action.ownerKey}`
        const game = yield call(request.get, apiUrl)
        yield put(setGame(humps.camelizeKeys(game.data)))
    } catch (error) {
        console.log("error", error)
        // TODO
        reportError(error)
    }
}

export function* getGameWatcher() {
    yield takeLatest(GET_GAME, getGameSaga)
}
