import React, { Component } from "react"
import { getImageUrl } from "utils"

class FullScreen extends Component {

    constructor(props) {
        super(props)
    }

    fullScreen() {
        console.log(this)
        if (window.innerHeight === screen.height) {
            document.exitFullscreen()
        } else {
            document.documentElement.requestFullscreen()
        }
    }

    render() {
        return (
            <div onClick={this.fullScreen} className="glickon_fs_button">
                <img src={getImageUrl('full-screen.svg')} alt="FS Button" />
            </div>
        )
    }
}

export default FullScreen
