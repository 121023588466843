import React, { Component } from "react"
import { TimelineMax, Power0, Bounce, Back } from "gsap"
import { NOT_FOUND, INVALID_PIN, GAME_ALREADY_STARTED } from "sagas/player/actions/game"
import { getImageUrl, translate, remToPx, scrollToTop, getCookie } from "utils"
import Logo from "views/owner/common/logo"
import Digits from "views/player/common/digits"

class EnterPin extends Component {

    constructor(props) {
        super(props)
        this.state = { text: '', showContent: false }
    }

    componentDidMount() {
        const { login } = this.props
        const gameCookie = getCookie("play.glickon.com.game")
        if (gameCookie) {
            const { pin, nickname, playerKey, team } = JSON.parse(gameCookie)
            login(pin, nickname, playerKey, team)
        } else {
            /* this is done just for graphic purpose. On reload, if there is a cookie,
            don't show content on first render. Show it only if there is no cookie
            */
            this.setState({
                showContent: true
            })
        }
        scrollToTop()
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.showContent && this.state.showContent) {
            this.setupAnimations()
        }
    }

    setupAnimations() {
        let tl = new TimelineMax({ repeat: -1, repeatDelay: 1 })
        tl.to(this.firstRef, 0.5, { ease: Power0.easeNone, y: remToPx(-1) })
        tl.to(this.firstRef, 1, { ease: Bounce.easeOut, y: remToPx(0) })
        let tm = new TimelineMax({ repeat: -1, repeatDelay: 3 })
        tm.to(this.secondRef, 1, { ease: Back.easeOut.config(1), scale: 1.05 })
        tm.to(this.secondRef, 1, { ease: Back.easeOut.config(1), scale: 1 })

    }

    handleChange(e) {
        this.setState({ text: e.target.value })
    }

    submit() {
        const { getGame, setGameState } = this.props
        const pin = this.state.text
        if (pin.length !== 6) {
            setGameState(INVALID_PIN)
        } else {
            getGame(pin)
        }
        this.setState({ loading: false })
    }

    handleSubmit(e) {
        e.preventDefault()
        scrollToTop() // for graphic motives first scroll to top and then handle the interractions
        this.setState({ loading: true })
        setTimeout(this.submit.bind(this), 2000)
    }

    clearInput(e) {
        this.setState({ text: "" })
    }

    onDigit(value) {
        if (value.trim().length === 6) {
            this.setState({ loading: true })
            this.setState({ text: value }, () => {
                this.submit()
            })
        }
    }

    get error() {
        const { gameState } = this.props
        const gameAlreadyStarted = gameState === GAME_ALREADY_STARTED
        const invalidGamePin = gameState === INVALID_PIN
        const gameNotFound = gameState === NOT_FOUND
        const notFound = gameNotFound ? (
            <div className="glickon_alert_error">{translate("p-pin-error")}</div>
        ) : null
        const alreadyStarted = gameAlreadyStarted ? (
            <div className="glickon_alert_error">{translate("p-game-started-error")}</div>
        ) : null
        const invalidPin = invalidGamePin ? (
            <div className="glickon_alert_error">{translate("p-invalid-pin")}<br />{translate("p-check-and-retry")}</div>
        ) : null
        return (
            <div>
                {invalidPin}
                {notFound}
                {alreadyStarted}
            </div>
        )
    }

    render() {
        return <article className="w-full h-screen flex relative flex-col">
            {
                this.state.showContent ?
                    <section className="flex flex-col pt-20 m-auto">
                        <div ref={div => this.firstRef = div}>
                            <Logo showDefault={true} hidePin={true}></Logo>
                        </div>
                        <div ref={div => this.secondRef = div} className="mt-6">
                            <p className="gt text-4xl text-gray-900 m-auto text-center">{translate("p-welcome")}</p>
                            <p className="text-xs text-gray-400 m-auto text-center w-[60%]">{translate("p-enter-pin")}</p>
                        </div>
                        {this.error && <div className="m-auto w-full h-auto p-4">{this.error}</div>}
                        <div className="m-auto">
                            <Digits onDigit={(e) => this.onDigit(e)}></Digits>
                        </div>
                    </section> :
                    <section className="glickon_spinner_wrapper">
                        <div className="glickon_spinner">&nbsp;</div>
                    </section>
            }
            <section className="mt-auto w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                <div className="w-full m-auto flex items-center justify-center">
                    <img src={getImageUrl('powered.svg')} alt="Logo" />
                </div>
            </section>
        </article>
    }
}

export default EnterPin
