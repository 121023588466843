import React, { Component } from "react"
import { intervalToDuration } from 'date-fns'
import Logo from "views/owner/common/logo"
import Answers from "views/owner/common/answers"
import Progress from "views/owner/common/progress"
import { getImageUrl } from "utils"

class Question extends Component {

    constructor(props) {
        super(props)
        this.state = {
            partialTimer: null,
            partialTimerRemaining: 5
        }
    }


    componentDidMount() {
        this.onKeyPress = this._onKeyPress.bind(this)
        document.body.addEventListener("keypress", this.onKeyPress)
        this.state.partialTimer = setInterval(() => {
            this.setState({
                partialTimerRemaining: (this.state.partialTimerRemaining - 1)
            })
            if (this.state.partialTimerRemaining === 0) { clearInterval(this.state.partialTimer) }
        }, 1000)
    }

    componentWillUnmount() {
        document.body.removeEventListener("keypress", this.onKeyPress)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.timeElapsed === this.props.currentQuestion.time) {
            this.props.getQuestionResult()
        }
    }

    _onKeyPress(e) {
        if (e.keyCode === 13) { // keyCode for "Enter"
            this.props.getQuestionResult()
        }
    }

    get numberOfAnswers() {
        const { liveAnswers, isSurvey } = this.props
        if (isSurvey) {
            return liveAnswers.totalAnswers
        } else {
            return parseInt(liveAnswers.correctAnswers, 10) + parseInt(liveAnswers.wrongAnswers, 10)
        }
    }

    getFormattedRemainingTime(seconds) {
        const duration = intervalToDuration({ start: seconds * 1000, end: 0 })
        const zeroPad = (num) => String(num).padStart(2, '0')
        return `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`
    }

    hasStarted() {
        return this.props.timeElapsed !== 0
    }

    render() {
        let { liveAnswers, currentQuestion, currentQuestionNumber, timeElapsed, game, waitingForPlayers, isSurvey } = this.props
        let numberOfAnswers = liveAnswers ? this.numberOfAnswers : 0
        return (
            <article className="h-screen w-full relative">
                <section className="flex h-screen">
                    <section className="w-[40%] flex place-content-center place-items-center border-r-gray-100 border-r">
                        <div className="flex flex-col h-[70vh]">
                            <Logo game={game} start={false} showDefault={false}></Logo>
                            <div className="m-auto mt-6 md:mt-20 w-full flex flex-col">
                                <div className="flex justify-center items-center">
                                    {currentQuestionNumber + 1} of {Object.keys(game.data.questions).length}
                                </div>
                                <div className="mt-1 md:mt-2 flex justify-center items-center">
                                    {this.hasStarted() && Array.from(this.getFormattedRemainingTime(currentQuestion.time - timeElapsed))
                                        .map((digit, i) => { return <span key={i} className="text-gray-900 text-5xl">{digit}</span> })}
                                </div>
                                <div className="mt-2 md:mt-8 flex justify-center items-center">
                                    {!this.hasStarted() && <Progress time={this.state.partialTimerRemaining} color={'#d0d5dd'}></Progress>}
                                    {this.hasStarted() &&
                                        <Progress
                                            time={currentQuestion.time - timeElapsed}
                                            becomesRed={currentQuestion.time - timeElapsed <= (currentQuestion.time / 4)}
                                            color={'#84CE67'}>
                                        </Progress>
                                    }
                                </div>
                            </div>
                            {
                                this.hasStarted() && <div className="m-auto mt-20 w-full justify-center flex">
                                    <div className="flex justify-center items-center">
                                        <img className="mr-2" src={getImageUrl('users.svg')} alt="Logo" />
                                        <div className="flex items-center justify-center">
                                            <span className="mr-2 text-gray-400">{`${numberOfAnswers}`}</span>
                                            <span className="mr-2">/</span>
                                            <span className="mr-2">
                                                {`${waitingForPlayers.players.numberOfPlayers} ${waitingForPlayers.players.numberOfPlayers === 1 ? 'risposta' : 'risposte'}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                    <section className="w-[60%] flex place-content-center place-items-center glickon_bg_question" style={{
                        backgroundImage: `url("${getImageUrl('bg.svg')}")`,
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        <div className="w-[80%]">
                            {
                                this.hasStarted() ?
                                    <Answers isSurvey={isSurvey} animated={true} answers={currentQuestion.answers} question={currentQuestion} /> :
                                    <div>
                                        <p className="text-gray-900 text-6xl gt">{currentQuestion.text}</p>
                                    </div>
                            }
                        </div>
                    </section>
                </section>
                <section className="bottom absolute bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-[70%] m-auto flex items-center justify-between">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                        <button disabled={true} className="flex items-center justify-center glickon_button_play bg-white border-black border rounded-lg p-2 text-black opacity-50">
                            <span>Avanti</span>
                            <img className="ml-2" src={getImageUrl('arrow-right-black.svg')} alt="Arrow" />
                        </button>
                    </div>
                </section>
            </article>
        )
    }
}

export default Question
