import {SET_GAME_LEADERBOARD} from "sagas/owner/actions/actionTypes"

export function gameLeaderboard(state = null, action) {
    switch (action.type) {
        case SET_GAME_LEADERBOARD:
            return action.data

        default:
            return state
    }
}
