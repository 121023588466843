import React, { Component, Fragment } from "react"
import { TweenMax, Elastic } from "gsap"
import "./index.scss"
import { getImageUrl, remToPx, translate } from "utils"
import Logo from "views/owner/common/logo"

const answersStyles = [
    {
        letter: 'A',
        colorClass: 'bg-brandy-bear-200'
    },
    {
        letter: 'B',
        colorClass: 'bg-primary-200'
    },
    {
        letter: 'C',
        colorClass: 'bg-indiviolet-sunset-200'
    },
    {
        letter: 'D',
        colorClass: 'bg-warning-100'
    }
]

class SurveyEnd extends Component {
    state = {
        currentQuestionNumber: undefined // it is either undefined (shows landing page) or number of question
    }
    componentDidMount() {
        this.setupAnimations()
        window.addEventListener("beforeunload", this.onBeforeUnload)
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onBeforeUnload)
    }

    onBeforeUnload(e) {
        e.preventDefault()
        e.returnValue = "Do you really want to leave our brilliant application?"
    }

    setupAnimations() {
        if (this.firstRef) {
            TweenMax.to(this.firstRef, 1, { y: remToPx(-20), ease: Elastic.easeOut.config(0.5, 0.35) })
        }
    }

    updateCurrentQuestion(number) {
        this.setState({ currentQuestionNumber: number })
    }

    get title() {
        const { info: { endTitle } } = this.props.game
        return endTitle ? endTitle : "Grazie mille di aver risposto alle domande!"
    }

    get text() {
        const { info: { endText } } = this.props.game
        return endText ? (
            <div className="" dangerouslySetInnerHTML={{ __html: endText }} />
        ) : (
            <div className="">
                Ti ringraziamo per aver partecipato. Per noi è molto importante la tua opinione per migliorare il tuo lavoro.
            </div>
        )
    }

    get resultsReview() {
        let { game } = this.props
        let { currentQuestionNumber } = this.state
        let { game: { questions }, results } = this.props
        game = {
            data: {
                ...game,
                info: { ...game.info }
            }
        }
        return (
            <article className="h-screen w-full relative" style={{
                backgroundImage: `url("${getImageUrl('bg.svg')}")`,
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <section className="flex h-screen place-content-center place-items-center">
                    <div className="w-[50%]">
                        <div>
                            <div className="text-gray-400">
                                {currentQuestionNumber + 1} of {Object.keys(game.data.questions).length.toString()}
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="text-gray-900 text-[24px] gt">{questions[currentQuestionNumber].text}</p>
                        </div>
                        <div className="mt-2">
                            {
                                questions[currentQuestionNumber].answers.map((answer, index) => {

                                    let stat
                                    if (results.length) {
                                        stat = results[currentQuestionNumber][`answer${index + 1}Stats`] + '%'
                                    }

                                    if (answer.text) {
                                        return <div style={{
                                            background: `linear-gradient(90deg, #D0D5DD ${stat}, #EAECF0 ${stat})`
                                        }} className='mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg bg-[#EAECF0]' key={index}>
                                            <div className="flex justify-between items-center">
                                                <span className="text-gray-900 text-[16px] font-normal">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                                                <span className="text-gray-400 text-xs">{stat}</span>
                                            </div>
                                        </div>
                                    }
                                })
                            }
                        </div>
                        <div className="w-full flex justify-center items-center mt-10">
                            <div className="mr-8">
                                {currentQuestionNumber > 0 &&
                                    <button className="glickon_btn_back"
                                        onClick={() => this.updateCurrentQuestion(currentQuestionNumber - 1)}>{translate('o-review-questions-back')}</button>
                                }
                            </div>
                            <div>
                                {currentQuestionNumber < questions.length - 1 &&
                                    <button className="glickon_btn_foward"
                                        onClick={() => this.updateCurrentQuestion(currentQuestionNumber + 1)} >{translate('o-review-questions-next')}</button>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bottom absolute bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-full m-auto flex items-center justify-center">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                    </div>
                </section>
            </article>
        )
    }

    get initialPage() {
        let { game } = this.props
        game = {
            data: {
                info: { ...game.info }
            }
        }
        return (
            <article className="h-screen w-full relative">
                <section className="flex h-screen place-content-center place-items-center">
                    <div className="w-[50%]">
                        <div>
                            <Logo game={game} start={false} hidePin={true} showDefault={false}></Logo>
                        </div>
                        <div className="text-center flex justify-center items-center mt-6 gt text-4xl">
                            <p>{this.title}</p>
                        </div>
                        <div className="text-center flex justify-center items-center mt-2 font-light text-gray-500 text-md">
                            {this.text}
                        </div>
                        <div className="mt-8 mb-8"><hr /></div>
                        <div className="mt-4 flex justify-center items-center">
                            <div className="w-[20%] m-auto">
                                <button onClick={() => this.updateCurrentQuestion(0)} className="glickon_button">
                                    {translate('o-review-questions')}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bottom absolute bottom-0 left-0 w-full p-4 flex place-content-center place-items-center bg-[#FFEDDB]">
                    <div className="w-full m-auto flex items-center justify-center">
                        <img src={getImageUrl('powered.svg')} alt="Logo" />
                    </div>
                </section>
            </article>
        )
    }

    render() {
        return this.state.currentQuestionNumber === undefined ? this.initialPage : this.resultsReview
    }
}

export default SurveyEnd
