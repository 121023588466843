import {RESET_ANSWERS} from "sagas/owner/actions/actionTypes"

/**
 * Action called from owner/playing component to reset answers to previous question
 */
export function resetAnswers() {
    return {
        type: RESET_ANSWERS
    }
}
