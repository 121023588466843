import {delay} from "redux-saga/effects"
import {put, select, call} from "redux-saga/effects"
import * as actionTypes from "sagas/owner/actions/actionTypes"
import {O_PLAYING} from "appStates/owner"

export function stopCountdown() {
    // TODO! call this method when an error is raised
    return {
        type: actionTypes.O_STOP_COUNTDOWN,
    }
}

export function setTimeElapsed(timeElapsed) {
    return {
        type: actionTypes.O_SET_TIME_ELAPSED,
        timeElapsed,
    }
}

export function* startCountdown() {
    let {appStateOwner} = yield select((state) => state.global)
    while (appStateOwner === O_PLAYING) {
        yield delay(1000)
        let {question} = yield select((state) => state.views.playing)
        if (question.currentQuestion) {
            if (question.timeElapsed >= question.currentQuestion.time) {
                yield put(stopCountdown())
            } else {
                yield put(setTimeElapsed(question.timeElapsed + 1))
            }
        }
    }
}
