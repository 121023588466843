import {createStore, applyMiddleware, compose, combineReducers} from "redux"
import createSagaMiddleware from "redux-saga"

export function getStore(reducers, runSagas) {
    const sagaMiddleware = createSagaMiddleware()
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const store = createStore(
        combineReducers(Object.assign({}, reducers)),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        composeEnhancers(
            applyMiddleware(sagaMiddleware)
        )
    )
    runSagas(sagaMiddleware)
    return store
}
