import {combineReducers} from "redux"
import waitingForPlayers from "sagas/owner/reducers/views/waitingForPlayers"
import playing from "sagas/owner/reducers/views/playing"
import {gameLeaderboard} from "sagas/owner/reducers/views/gameLeaderboard"

export default combineReducers({
    waitingForPlayers,
    playing,
    gameLeaderboard
})
