import React from "react"
import { connect } from "react-redux"
import Playing from "views/owner/playing/components/playing"
import { getNextQuestion, getQuestionResult, resetAnswers, resetQuestionResult } from "sagas/owner/actions/playing"
import { setAppState } from "sagas/owner/actions/appState"
import { getGame } from "sagas/owner/actions/game"

let Container = ({ ...props }) => <Playing {...props} />

const mapStateToProps = (state) => ({
    waitingForPlayers: state.views.waitingForPlayers,
    game: state.global.game,
    question: state.views.playing.question,
    liveAnswers: state.views.playing.answers,
    result: state.views.playing.result
})
const mapDispatchToProps = (dispatch) => ({
    getGame: (pin, ownerKey) => dispatch(getGame(pin, ownerKey)), // TODO non usata
    getNextQuestion: (pin, ownerKey) => dispatch(getNextQuestion(pin, ownerKey)),
    getQuestionResult: (pin, ownerKey) => dispatch(getQuestionResult(pin, ownerKey)),
    resetAnswers: () => dispatch(resetAnswers()),
    resetQuestionResult: () => dispatch(resetQuestionResult()),
    setAppState: (status) => dispatch(setAppState(status))
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
