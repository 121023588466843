import {RESET_QUESTION_RESULT} from "sagas/player/actions/actionTypes"

/**
 * Action called by player/playing to reset result of the previous question
 */
export function resetQuestionResult() {
    return {
        type: RESET_QUESTION_RESULT
    }
}
