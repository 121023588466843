import { mapValues, merge } from "lodash"
import player from "./player"
import owner from "./owner"
import common from "./common"

let mergedTranslations = merge(player, owner, common)

const translations = {
    en: { translation: mapValues(mergedTranslations, function (t) { return t.en }) },
    it: { translation: mapValues(mergedTranslations, function (t) { return t.it }) }
}

export default translations
