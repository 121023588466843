import {SET_ANSWERS, RESET_ANSWERS} from "sagas/owner/actions/actionTypes"

export function answers(state = null, action) {
    switch (action.type) {
        case SET_ANSWERS:
            return action.data
        case RESET_ANSWERS:
            return null

        default:
            return state
    }
}
