import React from "react"
import Select from "react-select"
import selectCustomStyles from "./selectStyles"

const SelectSpecialization = ({ handleSpecializationChange }) => {
    const options = [
        { value: "AERONAUTICA", label: "AERONAUTICA" },
        { value: "AGRICOLTURA E AMBIENTE", label: "AGRICOLTURA E AMBIENTE" },
        {
            value: "ALBERGHIERO E RISTORAZIONE",
            label: "ALBERGHIERO E RISTORAZIONE",
        },
        { value: "AMMINISTRAZIONE", label: "AMMINISTRAZIONE" },
        { value: "AREA TECNICO-SCIENTIFICA", label: "AREA TECNICO-SCIENTIFICA" },
        { value: "ARTIGIANATO", label: "ARTIGIANATO" },
        {
            value: "CHIMICA, MATERIALI E BIOTECNOLOGIA",
            label: "CHIMICA, MATERIALI E BIOTECNOLOGIA",
        },
        { value: "EDILIZIA", label: "EDILIZIA" },
        {
            value: "ELETTRONICA ED ELETTROTECNICA",
            label: "ELETTRONICA ED ELETTROTECNICA",
        },
        {
            value: "INFORMATICA E TELECOMUNICAZIONI",
            label: "INFORMATICA E TELECOMUNICAZIONI",
        },
        { value: "LINGUE", label: "LINGUE" },
        {
            value: "MECCANICA, MECCATRONICA ED ENERGIA",
            label: "MECCANICA, MECCATRONICA ED ENERGIA",
        },
        { value: "OTTICA", label: "OTTICA" },
        {
            value: "SERVIZI COMMERCIALI E TURISTICI",
            label: "SERVIZI COMMERCIALI E TURISTICI",
        },
        { value: "SERVIZI SOCIOSANITARI", label: "SERVIZI SOCIOSANITARI" },
        { value: "TRASPORTI E LOGISTICA", label: "TRASPORTI E LOGISTICA" },
        { value: "ALTRO", label: "ALTRO" },
    ]

    return (
        <div>
            <Select
                name="spacialization"
                placeholder="Seleziona l'area di studio"
                styles={selectCustomStyles}
                autosize={true}
                options={options}
                onChange={(e) => handleSpecializationChange(e)}
            />
        </div>
    )
}

export default ({ handleSpecializationChange }) => {
    return (
        <>
            <div className="glickon_input_wrapper mb-3">
                <SelectSpecialization
                    handleSpecializationChange={handleSpecializationChange}
                />
            </div>
        </>
    )
}
