import * as request from "axios"
import humps from "humps"
import {delay} from "redux-saga/effects"
import {call, put, takeLatest, fork, take, cancel} from "redux-saga/effects"
import {
    GET_NEXT_QUESTION,
    O_STOP_COUNTDOWN,
} from "sagas/owner/actions/actionTypes"
import {setQuestion} from "sagas/owner/actions/playing/setQuestion"
import {
    stopCountdown,
    startCountdown,
} from "sagas/owner/actions/playing/countdown"
import {reportError} from "sagas/global/actions/error"

export function getNextQuestion(pin, ownerKey) {
    return {
        type: GET_NEXT_QUESTION,
        pin,
        ownerKey,
    }
}

function* getNextQuestionSaga(action) {
    try {
        yield put(stopCountdown()) // stop previous countdown
        const apiUrl = `/api/owner/games/${action.pin}/${action.ownerKey}/next_question`
        const question = yield call(request.post, apiUrl)
        yield put(setQuestion(humps.camelizeKeys(question.data)))
        yield delay(5000) // time of the question preview
        let timer = yield fork(startCountdown)
        yield take(O_STOP_COUNTDOWN)
        yield cancel(timer)
    } catch (error) {
        console.log("error", error)
        reportError(error)
    }
}

export function* getNextQuestionWatcher() {
    yield takeLatest(GET_NEXT_QUESTION, getNextQuestionSaga)
}
