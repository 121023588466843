import React from "react"
import { connect } from "react-redux"
import PlayerSurveyEnded from "views/player/playerSurveyEnd/components/playerSurveyEnd"

let Container = ({ ...props }) => <PlayerSurveyEnded {...props} />

const mapStateToProps = (state) => ({
    game: state.global.game,
    endTitle: state.global.game.data.endTitle,
    endText: state.global.game.data.endText
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
