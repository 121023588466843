import { setPlayers } from "sagas/owner/actions/waitingForPlayers/players"
import { setQuestion } from "sagas/player/actions/playing/setQuestion"
import { setQuestionResult } from "sagas/player/actions/playing/setQuestionResult"
import { setAnswers } from "sagas/owner/actions/playing/setAnswers"
import { PLAYER_LEADERBOARD, SURVEY_END } from "appStates/player"
import { setPodium } from "sagas/player/actions/playerLeaderboard/setPodium"
import { setAppState } from "sagas/player/actions/appState"
import constants from "appConstants"
import { storeForScope } from "./index"

if (process.env.NODE_ENV === "production") {
    Pusher.log = function (message) {
        if (window.console && window.console.log) {
            window.console.log(message)
        }
    }
}
const encrypted = process.env.NODE_ENV === "production"

export function setupPusherForOwner(pin, ownerKey) {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        // eslint-disable-line no-undef
        authEndpoint: `/live/api/owner/games/${pin}/pusher_auth`,
        encrypted: encrypted,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        auth: {
            params: {
                owner_key: ownerKey, // eslint-disable-line camelcase
            },
        },
    })
    let ownerChannel = pusher.subscribe(`private-owner-${pin}`)

    ownerChannel.bind("players-joined", function (data) {
        // console.log("players-joined", data)
        storeForScope.dispatch(setPlayers(data))
    })

    // Uncomment to test player addition without having 54 devices
    /* let players = []
    setInterval(() => {
        let r = (Math.random() + 1).toString(36).substring(7);
        players.push(r)
        storeForScope.dispatch(setPlayers({
            "number_of_players": players.length,
            "latest_joined_players": players
        }))
    }, 2500) */

    ownerChannel.bind("players-answered", function (data) {
        // console.log("players-answered", data)
        storeForScope.dispatch(setAnswers(data))
    })
}

export function setupPusherForPlayer(pin, nickname, playerKey) {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        // eslint-disable-line no-undef
        authEndpoint: `/live/api/player/games/${pin}/pusher_auth`,
        encrypted: encrypted,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        auth: {
            params: {
                nickname: nickname,
                player_key: playerKey, // eslint-disable-line camelcase
            },
        },
    })
    let playerChannel = pusher.subscribe(`private-players-${pin}`)

    playerChannel.bind("next-question", function (data) {
        console.log('next-question"', data)
        storeForScope.dispatch(setQuestion(data))
    })

    playerChannel.bind("question-results", function (data) {
        console.log('question-results', data)
        storeForScope.dispatch(setQuestionResult(data))
    })

    playerChannel.bind("question-statistics", function (data) {
        console.log('question-statistics', data)
        storeForScope.dispatch(setQuestionResult(data))
    })

    playerChannel.bind("game-is-ended", function (data) {
        const state = storeForScope.getState()
        const {
            global: {
                game: {
                    data: { gameMode },
                },
            },
        } = state

        if (gameMode === constants.MODE_SURVEY) {
            storeForScope.dispatch(setAppState(SURVEY_END))
        } else {
            storeForScope.dispatch(setPodium(data))
            storeForScope.dispatch(setAppState(PLAYER_LEADERBOARD))
        }
    })
}
