// the results of all questions, that can be used to review each question (on owner side), once the game is ended
import {SET_QUESTION_RESULT} from "sagas/owner/actions/actionTypes"

export function results(state = [], action) {
    switch (action.type) {
        case SET_QUESTION_RESULT:
            return [...state, action.data]
        default:
            return state
    }
}
