export const SET_APP_STATE = "P_SET_APP_STATE"

export const SET_CURRENT_QUESTION = "P_SET_CURRENT_QUESTION"

export const LOGIN = "P_LOGIN"

export const JOIN_GAME = "P_JOIN_GAME"
export const JOIN_TEAM = "P_JOIN_TEAM"
export const GET_GAME = "P_GET_GAME"
export const SET_GAME = "P_SET_GAME"
export const SET_GAME_STATE = "P_SET_GAME_STATE"
export const SET_PLAYER = "P_SET_PLAYER"
export const SET_QUESTION = "P_SET_QUESTION"
export const SEND_ANSWER = "P_SEND_ANSWER"
export const SET_SCORE = "P_SET_SCORE"
export const RESET_SCORE = "P_RESET_SCORE"
export const SET_QUESTION_RESULT = "P_SET_QUESTION_RESULT"
export const RESET_QUESTION_RESULT = "P_RESET_QUESTION_RESULT"
export const GET_PLAYER_LEADERBOARD = "P_GET_PLAYER_LEADERBOARD"
export const SET_PLAYER_LEADERBOARD = "P_SET_PLAYER_LEADERBOARD"
export const SET_PODIUM = "P_SET_PODIUM"
export const STOP_COUNTDOWN = "P_STOP_COUNTDOWN"
export const SET_TIME_ELAPSED = "P_SET_TIME_ELAPSED"
