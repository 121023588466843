import {loginWatcher, getGameWatcher, joinGameWatcher, joinTeamWatcher} from "sagas/player/actions/game"
import {sendAnswerWatcher, setQuestionWatcher} from "sagas/player/actions/playing"
import {getPlayerLeaderboardWatcher} from "sagas/player/actions/playerLeaderboard"

export default function runPlayerSagas(sagaMiddleware) {
    sagaMiddleware.run(loginWatcher)
    sagaMiddleware.run(getGameWatcher)
    sagaMiddleware.run(joinGameWatcher)
    sagaMiddleware.run(sendAnswerWatcher)
    sagaMiddleware.run(getPlayerLeaderboardWatcher)
    sagaMiddleware.run(setQuestionWatcher)
    sagaMiddleware.run(joinTeamWatcher)
}
