import {SET_GAME} from "../actionTypes"

/**
 * Action called (only) by saga to set game info
 */
export function setGame(data) {
    return {
        type: SET_GAME,
        data,
    }
}
