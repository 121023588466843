import React, { Component } from "react"
import Progress from "views/owner/common/progress"
import classNames from "classnames"
import { intervalToDuration } from 'date-fns'
import constants from "appConstants"

const answersStyles = [
    {
        letter: 'A',
        colorClass: 'bg-brandy-bear-200'
    },
    {
        letter: 'B',
        colorClass: 'bg-primary-200'
    },
    {
        letter: 'C',
        colorClass: 'bg-indiviolet-sunset-200'
    },
    {
        letter: 'D',
        colorClass: 'bg-warning-100'
    }
]

class Question extends Component {

    constructor(props) {
        super(props)
        this.state = {
            partialTimer: null,
            partialTimerRemaining: 5,
            answer: null,
            hasAnswered: false
        }
    }

    componentDidMount() {
        this.state.partialTimer = setInterval(() => {
            this.setState({
                partialTimerRemaining: (this.state.partialTimerRemaining - 1)
            })
            if (this.state.partialTimerRemaining === 0) { clearInterval(this.state.partialTimer) }
        }, 1000)
    }

    sendAnswer(answer) {
        let { score } = this.props
        if (!this.state.hasAnswered) {
            this.props.sendAnswer(this.props.game.data.info.pin, this.props.player.nickname, this.props.player.playerKey, answer)
        }
    }

    handleClick(answer) {
        if (!this.state.hasAnswered) { // once the answer is selected it can't be changed
            this.setState({ hasAnswered: true })
            this.setState({ answer: answer.position })
            this.sendAnswer(answer.position)
        }
    }

    get answers() {
        let isSurvey = this.props.game.data.info.gameMode === constants.MODE_SURVEY
        let { question } = this.props
        let { result } = this.props
        return question.answers.map((answer, index) => {
            let playerRespondedStyle
            if (this.state.answer === answer.position) {
                playerRespondedStyle = { backgroundColor: '#010928', color: "#ffffff" }
            }
            if (isSurvey) {
                if (result) {
                    return <div style={{
                        background: answer.isCorrect ? `linear-gradient(90deg, #84CE67 100%, #F2F4F7 100%)` : `linear-gradient(90deg, #D0D5DD 100%, #F2F4F7 100%)`
                    }} className={classNames(['bg-[#EAECF0] mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg'])} key={index}>
                        <span className="text-gray-900 text-[16px] font-medium">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                    </div>
                }
                return answer.text ? <div style={playerRespondedStyle} onClick={() => this.handleClick(answer)} className={classNames(['bg-[#EAECF0] mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg'])} key={index}>
                    <span style={playerRespondedStyle} className="text-gray-900 text-[16px] font-medium">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                </div> : null
            } else {
                if (result) {
                    return <div style={{
                        background: answer.isCorrect ? `linear-gradient(90deg, #84CE67 100%, #F2F4F7 100%)` : `linear-gradient(90deg, #D0D5DD 100%, #F2F4F7 100%)`
                    }} className={classNames([answersStyles[index].colorClass, 'mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg'])} key={index}>
                        <span className="text-gray-900 text-[16px] font-medium">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                    </div>
                }
                return answer.text ? <div style={playerRespondedStyle} onClick={() => this.handleClick(answer)} className={classNames([answersStyles[index].colorClass, 'mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg'])} key={index}>
                    <span style={playerRespondedStyle} className="text-gray-900 text-[16px]font-medium">{`${answersStyles[index].letter}) ${answer.text}`}</span>
                </div> : null
            }

        })
    }

    getFormattedRemainingTime(seconds) {
        const duration = intervalToDuration({ start: seconds * 1000, end: 0 })
        const zeroPad = (num) => String(num).padStart(2, '0')
        return `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`
    }

    render() {
        let isSurvey = this.props.game.data.info.gameMode === constants.MODE_SURVEY
        let { timeElapsed, currentQuestionNumber, question } = this.props
        let { score } = this.props
        return <>
            {timeElapsed === 0
                && <section>
                    <div className="w-full p-4">
                        <div className="mb-4 flex justify-between items-center">
                            <span>{currentQuestionNumber + 1} of {this.props.game.data.info.numberOfQuestions}</span>
                            <span style={{ opacity: isSurvey && '0' }} className="glickon_badge_pt small">{score.points}pt</span>
                        </div>
                        <div>
                            <Progress time={this.state.partialTimerRemaining} color={'#d0d5dd'} small={true}></Progress>
                        </div>
                    </div>
                    <div>
                        <div className="gt text-4xl text-gray-900 m-4" dangerouslySetInnerHTML={{ __html: question.text }} />
                    </div>
                </section>
            }
            {timeElapsed !== 0 &&
                <section>
                    <div className="w-full p-4">
                        <div className="mb-2 flex justify-between items-start">
                            <span>{currentQuestionNumber + 1} of {this.props.game.data.info.numberOfQuestions}</span>
                            <span className="text-xl font-bold">
                                {this.getFormattedRemainingTime(question.time - timeElapsed)}
                            </span>
                            <span style={{ opacity: isSurvey && '0' }} className="glickon_badge_pt small">{score.points}pt</span>
                        </div>
                        <div>
                            <Progress
                                time={question.time - timeElapsed}
                                becomesRed={question.time - timeElapsed <= (question.time / 4)}
                                color={'#84CE67'}
                                small={true}
                            ></Progress>
                        </div>
                    </div>
                    {question.imageUrl &&
                        <div className="w-full flex items-center p-4 pb-2">
                            <img className="max-h-full max-w-full rounded-tr-lg rounded-br-lg rounded-bl-lg" src={question.imageUrl} alt="Question image" />
                        </div>
                    }
                    <div>
                        <div className="gt text-[24px] text-gray-900 m-4 mb-2" dangerouslySetInnerHTML={{ __html: question.text }} />
                    </div>
                    <div className="p-4 pt-2">
                        {this.answers}
                    </div>
                </section>
            }
        </>
    }
}

export default Question
