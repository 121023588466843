import * as request from "axios"
import {call, put, takeLatest} from "redux-saga/effects"
import {GET_GAME_LEADERBOARD} from "sagas/owner/actions/actionTypes"
import {setGameLeaderboard} from "sagas/owner/actions/gameLeaderboard/setGameLeaderboard"
import {reportError} from "sagas/global/actions/error"

export function getGameLeaderboard(pin, ownerKey) {
    return {
        type: GET_GAME_LEADERBOARD,
        pin,
        ownerKey
    }
}

function* getGameLeaderboardSaga(action) {
    try {
        const apiUrl = `/api/owner/games/${action.pin}/${action.ownerKey}/leaderboard`
        const game = yield call(request.get, apiUrl)
        yield put(setGameLeaderboard(game.data))
    } catch (error) {
        console.log("error", error)
        reportError(error)
    }
}

export function* getGameLeaderboardWatcher() {
    yield takeLatest(GET_GAME_LEADERBOARD, getGameLeaderboardSaga)
}
