import i18next from "i18next"
import {deburr} from "lodash"
import moment from "moment"

export function getCookie(name) {
    let cookieValue = null
    if (document.cookie && document.cookie !== "") {
        let cookies = document.cookie.split(";")
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim()
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                )
                break
            }
        }
    }
    return cookieValue
}

export function setCookie(name, value, seconds) {
    let expires
    if (seconds) {
        let date = new Date()
        date.setTime(date.getTime() + seconds * 1000)
        expires = `; expires=${date.toGMTString()}`
    } else {
        expires = ""
    }
    document.cookie = `${name}=${value}${expires}; path=/; SameSite=Lax`
}

export function deleteCookie(name) {
    document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

/**
 * convert rem to px
 * */
export function remToPx(remValue) {
    function getRootElementFontSize() {
        return parseFloat(
            getComputedStyle(
                document.documentElement // root <html> element
            ).fontSize
        )
    }
    return remValue * getRootElementFontSize()
}

export function getImageUrl(image) {
    return `${process.env.REACT_APP_STATIC_URL}live/internal/img/${image}` // eslint-disable-line no-undef
}

export function getDocumentUrl(doc) {
    return `${process.env.REACT_APP_STATIC_URL}live/internal/documents/${doc}` // eslint-disable-line no-undef
}

export function translate(key, options) {
    return i18next.t(key, options)
}

export function setLanguage(language) {
    if (language === "en") {
        moment.locale("en")
        i18next.changeLanguage("en")
    } else {
        moment.locale("it")
        i18next.changeLanguage("it")
    }
}

export function scrollToTop() {
    window.scrollTo(0, 0)
}

export function toggleFullScreen() {
    let isInFullScreen =
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
            document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
            document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
    const elem = document.documentElement
    if (!isInFullScreen) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
            // Firefox
            elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
            // Chrome, Safari and Opera
            elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
            // IE/Edge
            elem.msRequestFullscreen()
        }
    } else {
        /* to enter full screen, we need to use the Element, however to exit fullscreen, we use document*/
        /* eslint-disable no-lonely-if */
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
            // Firefox
            document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
            // Chrome, Safari and Opera
            document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
            // IE/Edge
            document.msExitFullscreen()
        }
    }
}

const _bugsnagManager = (function () {
    let bugsnagInstance

    function setBugsnagInstance(instance) {
        bugsnagInstance = instance
    }

    function getBugsnagInstance() {
        if (!bugsnagInstance) {
            const err = "bugsnagInstance not set" // this assignment is just for eslint reasons, *no-throw-literal*
            throw err
        } else {
            return bugsnagInstance
        }
    }

    return {setBugsnagInstance, getBugsnagInstance}
})()

export function setBugsnagInstance(bugsnagInstance) {
    _bugsnagManager.setBugsnagInstance(bugsnagInstance)
}

export function getBugsnagInstance() {
    return _bugsnagManager.getBugsnagInstance()
}

export function preloadImages(images, callback) {
    let promises = images.map(function (image) {
        return new Promise(function (resolve, reject) {
            let img = new Image()
            img.onload = resolve
            img.src = image
        })
    })
    Promise.all(promises).then(function (results) {
        if (callback) {
            callback()
        }
    })
}

export function isNameForbidden(name) {
    const forbiddenNames = [
        // italian
        "arrapat",
        "bagasci",
        "balle",
        "bastard",
        "batton",
        "bocchin",
        "cacare",
        "cacca",
        "cagare",
        "cagna",
        "cagne",
        "cane",
        "kane",
        "cappell",
        "cazz",
        "checca",
        "chiava",
        "coglion",
        "cornut",
        "cortigian",
        "crepa",
        "cretin",
        "cristo",
        "culo",
        "culi",
        "deretan",
        "eiacula",
        "escrement",
        "farabutt",
        "feci",
        "fellatio",
        "fica",
        "figa",
        "finocchi",
        "finokki",
        "flatulenza",
        "fottere",
        "fottio",
        "fottuto",
        "fregna",
        "froci",
        "gay",
        "gesu",
        "gigolo",
        "glute",
        "gnocca",
        "greppo",
        "gretto",
        "handicap",
        "incula",
        "infinocchia",
        "invertit",
        "lecca",
        "lesbic",
        "loffa",
        "madonna",
        "mammell",
        "maron",
        "merd",
        "meretric",
        "mignott",
        "minchiat",
        "maledet",
        "muori",
        "palla",
        "palle",
        "paracul",
        "peluria",
        "pene",
        "peni",
        "piscia",
        "piscio",
        "pisello",
        "pompa",
        "pompin",
        "porc",
        "pork",
        "puttan",
        "sborr",
        "scem",
        "scopare",
        "scopata",
        "scoreggi",
        "sega",
        "seghe",
        "segaiolo",
        "sesso",
        "sfigat",
        "sfortunat",
        "sfottere",
        "sgualdrin",
        "stronz",
        "stupid",
        "sveltin",
        "suca",
        "suka",
        "tett",
        "travestit",
        "troia",
        "troie",
        "troiona",
        "tromba",
        "uccell",
        "vacca",
        "vacche",
        "vaffanculo",
        "vagin",
        "zoccol",
        // english
        "anal",
        "anus",
        "arse",
        "ballsack",
        "balls",
        "bastard",
        "bitch",
        "biatch",
        "bloody",
        "blowjob",
        "bollock",
        "bollok",
        "boner",
        "boob",
        "bugger",
        "butt",
        "buttplug",
        "clitoris",
        "cock",
        "coon",
        "crap",
        "christ",
        "cunt",
        "damn",
        "dick",
        "dildo",
        "dyke",
        "feck",
        "fellate",
        "fellatio",
        "felching",
        "fuck",
        "fudgepacker",
        "flange",
        "goddamn",
        "hell",
        "homo",
        "jerk",
        "jesus",
        "jizz",
        "knobend",
        "labia",
        "lmao",
        "lmfao",
        "muff",
        "nigger",
        "nigga",
        "penis",
        "poop",
        "prick",
        "pube",
        "pussy",
        "queer",
        "scrotum",
        "sex",
        "shit",
        "slut",
        "smegma",
        "spunk",
        "tosser",
        "turd",
        "twat",
        "vagina",
        "wank",
        "whore",
    ]
    // make name lowercase and remove all the spaces
    let strippedName = name.toLowerCase().replace(/\s/g, "")

    return forbiddenNames.find((x) => strippedName.includes(x))
}

export function stripAccents(string) {
    let text = string.toLowerCase()
    return deburr(text)
}

export function stripExtraSpaces(string) {
    return string.trim().replace(/\s+/g, " ")
}

export function isAlphabeticString(string) {
    const text = stripAccents(string)
    return text.match(/^[a-zA-Z\s]*$/i)
}

export function validateEmail(email) {
    let regex =
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i // eslint-disable-line no-useless-escape
    return regex.test(email.trim())
}
