import {SET_QUESTION_RESULT, RESET_QUESTION_RESULT} from "sagas/owner/actions/actionTypes"

export function result(state = null, action) {
    switch (action.type) {
        case SET_QUESTION_RESULT:
            return action.data
        case RESET_QUESTION_RESULT:
            return null

        default:
            return state
    }
}
