import React, { Component } from "react"
import { translate } from "utils"
import classNames from "classnames"
import Progress from "views/owner/common/progress"
import { TimelineMax, Back } from "gsap"
import constants from "appConstants"

const answersStyles = [
    {
        letter: 'A',
        colorClass: 'bg-brandy-bear-200'
    },
    {
        letter: 'B',
        colorClass: 'bg-primary-200'
    },
    {
        letter: 'C',
        colorClass: 'bg-indiviolet-sunset-200'
    },
    {
        letter: 'D',
        colorClass: 'bg-warning-100'
    }
]

class QuestionResult extends Component {

    constructor(props) {
        super(props)
        this.state = {
            alert: null
        }
    }

    componentDidMount() {
        this.setupAnimations()
    }

    setupAnimations() {
        if (this.state.alert) {
            let tm = new TimelineMax({ repeat: -1, repeatDelay: 3 })
            tm.to(this.state.alert, 1, { ease: Back.easeOut.config(1), scale: 1.05 })
            tm.to(this.state.alert, 1, { ease: Back.easeOut.config(1), scale: 1 })
        }
    }

    get answers() {
        let isSurvey = this.props.game.data.info.gameMode === constants.MODE_SURVEY
        let { question } = this.props
        let { result, score, answered } = this.props
        return question.answers.map((answer, index) => {
            const myAnswer = this.props.playerScore.answer
            const thisAnswer = index + 1

            // Case: this answer is my answer and it is correct -> Green
            // thisAnswer === score.answer && myAnswer === result.correctAnswerPosition + 1

            // Case: this answer is my answer and it is NOT correct -> Red
            // thisAnswer === score.answer && myAnswer !== result.correctAnswerPosition + 1

            if (answer.text) {
                let stat = result[`answer${index + 1}Stats`]
                let bg = ''
                if (thisAnswer === score.answer && myAnswer === result.correctAnswerPosition + 1) {
                    // I gave the correct answer
                    bg = `linear-gradient(90deg, rgb(132, 206, 103) ${stat}%, #010928 ${stat}%)`
                } else if (thisAnswer === score.answer && myAnswer !== result.correctAnswerPosition + 1) {
                    // I gave the wrong answer
                    bg = `linear-gradient(90deg, #E27A6F ${stat}%, #010928 ${stat}%)`
                } else if (thisAnswer === result.correctAnswerPosition + 1) {
                    // This answer is correct but I didn't give it
                    bg = `linear-gradient(90deg, rgb(132, 206, 103) ${stat}%, #F9FAFB ${stat}%)`
                } else {
                    // Somenone else gave this answer
                    bg = `linear-gradient(90deg, #F2F4F7 ${stat}%, #F9FAFB ${stat}%)`
                }
                if (isSurvey) {

                    // I gave this answer
                    if (thisAnswer === score.answer && myAnswer === thisAnswer) {
                        bg = `linear-gradient(90deg, #667085 ${stat}%, #010928 ${stat}%)`
                    } else {
                        // I did not give this answer
                        bg = `linear-gradient(90deg, #F2F4F7 ${stat}%, #F9FAFB ${stat}%)`
                    }


                    return <div style={{
                        background: answered ? bg : `linear-gradient(90deg, #F2F4F7 ${stat}%, #F9FAFB ${stat}%)`,
                    }} className={classNames('mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg flex justify-between items-center')} key={index}>
                        <span className={classNames({
                            'text-gray-900': thisAnswer !== score.answer && myAnswer !== thisAnswer || !answered,
                            'text-white': thisAnswer === score.answer && myAnswer === thisAnswer && answered
                        }, "text-[16px] font-medium")}>{`${answersStyles[index].letter}) ${answer.text}`}</span>
                        <span className={classNames({
                            'text-gray-400': thisAnswer !== score.answer && myAnswer !== thisAnswer || !answered,
                            'text-white': thisAnswer === score.answer && myAnswer === thisAnswer && answered
                        }, "text-xs font-bold")}>{stat}%</span>
                    </div>
                } else {
                    return <div style={{
                        background: answered ? bg : `linear-gradient(90deg, #F2F4F7 ${stat}%, #F9FAFB ${stat}%)`,
                        border: thisAnswer === result.correctAnswerPosition + 1 && stat === '0.0' ? '3px solid #84CE67' : ''
                    }} className={classNames('mt-2 p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg flex justify-between items-center')} key={index}>
                        <span className={classNames({
                            'text-gray-900': thisAnswer !== score.answer && myAnswer !== thisAnswer || !answered,
                            'text-white': thisAnswer === score.answer && myAnswer === thisAnswer && answered
                        }, "text-[16px] font-medium")}>{`${answersStyles[index].letter}) ${answer.text}`}</span>
                        <span className={classNames({
                            'text-gray-400': thisAnswer !== score.answer && myAnswer !== thisAnswer || !answered,
                            'text-white': thisAnswer === score.answer && myAnswer === thisAnswer && answered
                        }, "text-xs font-bold")}>{stat}%</span>
                    </div>
                }
            }
        })
    }

    render() {
        let isSurvey = this.props.game.data.info.gameMode === constants.MODE_SURVEY
        let { currentQuestionNumber, question, answered } = this.props
        let { result, score } = this.props
        const gotPoints = result.correctAnswerPosition + 1 === score.answer
        return (
            <section>
                <div className=" w-full p-4">
                    <div className="mb-2 flex justify-between items-start">
                        <span>{currentQuestionNumber + 1} of {this.props.game.data.info.numberOfQuestions}</span>
                        <span className="text-xl font-bold">
                            {'00:00'}
                        </span>
                        <span style={{ opacity: isSurvey && '0' }} className="glickon_badge_pt small">{score.points}pt</span>
                    </div>
                    <div className="mb-4">
                        <Progress time={0} color={'#d0d5dd'}></Progress>
                    </div>
                    {
                        !isSurvey &&
                        <div ref={(div) => this.state.alert = div}>
                            <div className={classNames({ "glickon_alert_success": gotPoints && answered, "glickon_alert_error": (!gotPoints && answered) || !answered })}>
                                {gotPoints && answered && translate("p-result-title-correct")}
                                {!gotPoints && answered && translate("p-result-title-wrong")}
                                {!answered && translate("p-result-title-empty")}
                            </div>
                        </div>
                    }
                </div>
                {question.imageUrl &&
                    <div className="w-full flex items-center p-4 pb-2">
                        <img className="max-h-full max-w-full rounded-tr-lg rounded-br-lg rounded-bl-lg" src={question.imageUrl} alt="Question image" />
                    </div>
                }
                <div>
                    <div className="gt text-[24px] text-gray-900 m-4 mb-2" dangerouslySetInnerHTML={{ __html: question.text }} />
                </div>
                <div className="p-4 pt-2">
                    {this.answers}
                </div>
            </section>
        )
    }
}

export default QuestionResult

